<template>
  <f7-page>
    <f7-navbar title="Learn">
      <f7-subnavbar style="background-color: #f2f2f7; opacity: 0.95;">
        <f7-segmented strong>
          <f7-button tab-link="#tab1" tab-link-active>Growing tips</f7-button>
          <f7-button tab-link="#tab2">Device info</f7-button>
        </f7-segmented>
      </f7-subnavbar>
    </f7-navbar>
    <f7-tabs>
      <!-- Growing tips -->
      <f7-tab id="tab1" tab-active>
        <f7-block-title medium>Growing Tips</f7-block-title>

        <f7-card expandable v-for="tips in growingTips" :id="'expendable' + tips.category_id"
                 @card:open="open()" @card:close="close()">
          <f7-card-content :padding="false">
            <div class="bg-color-green"
                 :style="{height: '300px', background: 'url(' + tips.img + ') no-repeat center bottom', 'background-size': 'cover'}">
              <!-- padding-top:1em; padding-left:1em; -->
              <f7-card-header text-color="white" class="display-block" style="width:80%;">
                {{ tips.title }}
                <br/>
                <small :style="{opacity: 0.7}">{{ tips.sub_title }}</small>
              </f7-card-header>
              <f7-link card-close color="white" class="card-opened-fade-in"
                       :style="{position: 'absolute', right: '15px', top: '15px'}"
                       icon-f7="xmark_circle_fill"></f7-link>
            </div>
            <div class="card-content-padding">
              <p v-html="tips.content"></p>

              <f7-block style="margin-bottom: 2em; margin-top:2em;" v-if="tips.title == 'Plant beauty tricks'">
                <f7-list class='inset' style='border:1px solid #e3e3e3; background-color:#f2f2f7;'>
                  <f7-list-item title='Master pruning' link='https://www.youtube.com/watch?v=qKiZfFchYU0'
                                external target='_blank' class='external'>
                    <f7-icon slot='media' f7='play_rectangle_fill' style='color: #FF0000;'></f7-icon>
                  </f7-list-item>
                </f7-list>
              </f7-block>

            </div>
          </f7-card-content>
        </f7-card>

      </f7-tab>

      <!-- Device Info -->
      <f7-tab id="tab2">
        <f7-block-title medium>Device Info</f7-block-title>
        <f7-card title="Device Info" class="shadow">
          <f7-card-content>
            <f7-list medial-list class="padding-b">
              <f7-list-item link="/water-level-sensor/" title="Water level sensor">
                <img slot="media" src="/static/learn/device_info_water_icon.png" width="34" class="icon-class"/>
              </f7-list-item>
              <f7-list-item link="/temperature/" title="Temperature sensor">
                <img slot="media" src="/static/learn/device_info_temp_icon.png" width="34" class="icon-class"/>
              </f7-list-item>
              <f7-list-item link="/lid/" title="Lifting of the Lid">
                <img slot="media" src="/static/learn/device_info_lid_icon.png" width="34" class="icon-class"/>
              </f7-list-item>
              <f7-list-item link="/light-info/" title="Growing lights">
                <img slot="media" src="/static/learn/device_info_light_icon.png" width="34" class="icon-class"/>
              </f7-list-item>
              <f7-list-item link="/cleaning-info/" title="Cleaning & maintenance">
                <img slot="media" src="/static/learn/device_info_clean_icon.png" width="34" class="icon-class"/>
              </f7-list-item>
              <f7-list-item link="/capsules-info/" title="Capsule setup">
                <img slot="media" src="/static/learn/device_info_capsules_icon.png" width="34" class="icon-class"/>
              </f7-list-item>
            </f7-list>
          </f7-card-content>
        </f7-card>
      </f7-tab>

      <!-- load first 2 images -->
      <img src="/static/tab1.png" style="display: none;">
      <img src="/static/tab3.png" style="display: none;">
    </f7-tabs>
  </f7-page>
</template>

<script>
export default {
  data() {
    return {
      growingTips: [
        {
          "category_id": 0,
          "title": "Embrace the Brown",
          "sub_title": "Understanding plant leaves.",
          "content": "If you've noticed the tips of your plant's leaves turning brown, don't worry! This is a normal part of the plant's growth process. <br/><br/>As plants mature, they naturally shed older leaves to make room for new growth. The brown leaves you see are simply the plant's way of telling you that it's time for them to be replaced with fresh, green leaves. So don't be alarmed, your plant is healthy and just doing what it's supposed to do. Keep an eye on your plant, and in no time, you'll see new growth emerging from the tips.<br/><br/>Happy gardening!",
          "img": "/static/tab5.png"
        },
        {
          "category_id": 0,
          "title": "Pancic's Spruce",
          "sub_title": "Step by step guide",
          "content": "🌲 &nbsp;Pancic Spruce is a little project for itself. It will take around 3-4 months in total to prepare and set up seeds, and nurture four little, fearless pines, that will be up to 10 cm tall in your Urbi.<br/><br/>" +
              "But, hey, aren't you amazing 💚 &nbsp; <br/>You'll be helping this endangered and ancient spruce to defy climate change and keep on growing! In Nature, this plant is quite rare - sudden temperature changes and insufficient light heavily impacted the ability of the seeds to germinate. 😔<br/><br/>" +
              "After your initial effort, Urbi will make sure every seed has the perfect amount of light and moisture.<br/><br/>" +
              "👍 &nbsp; <b>First steps:</b><br/>" +
              "1. Put the seeds in a small glass with tap water and keep them there for 8-10h.<br/>" +
              "2. Take the seeds out and place one seed per capsule<br/><br/>" +
              "🚀 &nbsp; <b>New stage - after 4 months:</b><br/>" +
              "When little spruces grow up to 10 cm, it's time for replanting!<br/>" +
              "<ul>" +
              "<li>Take <b>2-5 dl plastic cup</b>. Fill 2/3 with ordinary gardening soil</li>" +
              "<li>Take the spruces out of capsules and transplant them into the cups</li>" +
              "<li><b>Keep the plants on the window sill, inside.</b> Choose the window with the most light in your home.</li>" +
              "<li><b>Water the plants</b> moderately once or twice per week (check with your finger if the soil is dry)</li>" +
              "</ul>"
          ,
          "img": "/static/omorika-card.jpg"
        },
        {
          "category_id": 10,
          "title": "Sprouting 101",
          "sub_title": "Get to know your baby plants",
          "content": "Congratulations! Your baby plants went through a huge transformation from tiny seeds in just a few days (or more if you're growing parsley). If your sprouts have already reached their transparent domes, you can now freely remove them so they can continue to thrive on!<br/><br/>Although they are still a mini version of your plants, sprouts are packed with nutrients and essential vitamins such as vitamins E, A, and C. Some people even use them as an addition to soups, sandwiches, and salads!",
          "img": "/static/tab1.png"
        },
        {
          "category_id": 0,
          "title": "Adenium Bonsai",
          "sub_title": "Ultimate tips for beginners",
          "content": "Howdy, Bonsai lover! 🙂 <br/><br/>" +
              "Let's dive into the World of mystical Bonsai techniques with Adenium seeds. This plant is perfect to exercise and master Bonsai skill." +
              "And, as a result, you will be rewarded with four miniature and beautiful trees in UrbiGo.<br/><br/>" +
              "For starters: <br/>" +
              "<ul><li>Soak Adenium seeds in lukewarm water 24h before planting</li>" +
              "<li>Place just one seed per capsule </li>" +
              "<li>Germination time: 3-5 days </li></ul><br/>" +
              "<b>First month:</b><br/>" +
              "After 3-4 weeks, the first dark green leaves will appear on the main plant. New buds and branches should start to grow from the sides of the main plant.<br/><br/>" +
              "<b>Second month:</b><br/>" +
              "Cut the tip of the main plant with a clean, sharp knife. This will stimulate the growth of side branches and gentle curving of the trunk.<br/><br/>" +
              "<b>Third month:</b><br/>" +
              "It's time to transplant Bonsai trees in bigger pots.<br/><br/>",
          "img": "/static/bonsai_card.png"
        },
        {
          "category_id": 9,
          "title": "Plant beauty tricks",
          "sub_title": "Become trimming pro",
          //"content": "Growing your very own luscious herb garden can definitely be rewarding. However, if we don't properly take care of the plants they aren't going to be as healthy or productive. So, in order to get most of your herbs pruning is a must-have skill.<br/><br/>The good rule of thumb is to start pruning when your herbs are already 8-10 cm tall and actively growing. Never take off more than 1/3 of herb plant and always prune leaves on the top.<br/><br/>The shape of your herb will depend on the leaves you remove. If you want them to be bushier, use your fingers or small scissors to pinch off upper leaves or parts of the stem. Don't tear or rip off the stem from the ground. The shape of your herb will depend on the leaves you remove.",
          "content": "<h2>Everybody likes a fresh cut, so do your plants! 🌿</h2>" +
              "<p>Give them a little extra attention this week, if they seem growing fast,  and take the time to trim your plants so that they can continue to flourish!</p>" +
              "<p>Pruning encourages fresh growth, which often helps a tall, thin plant develop bushier foliage, increase the amounts of vitamins and minerals in leaves of herbs, or avoid suffocating neighboring plants by larger individuals.</p>" +
              "<p><span style='font-size: 28px;'>👉🏻</span> Note that if you grow micro species like micro cherry or lavender, they do not need pruning at all when grown in UrbiGo.</p>" +
              "<p><span style='font-size: 28px;'>💚 🔓</span> Click here to unlock a new urban gardening skill and become a trimming pro!</p>",
          "img": "/static/tab3.png"
        },
        {
          "category_id": 8,
          "title": "Mold alert",
          "sub_title": "Why it happens and what to do next?",
          //"content": "You may have noticed the dusty-looking mold on the soil surface in your UrbiGo capsules. Although it can seem a bit unattractive, there's no real need to fear.<br/><br/>The unwanted visitor is actually a harmless saprophytic fungus and you can get rid of it in a few simple ways. The first step is to remove the mold by hand, using a clean cotton cloth. Additionally, you can create organic mold repellent by mixing natrium-bicarbonate with water. Spray the solution on the plant and the soil, and voila. Your plants are now mold-proof!",
          "content": "<p>You may have noticed the dusty-looking white mold on the soil surface of your UrbiGo capsules.</p>" +
              "<p>Although it may seem a bit unattractive, usually there is no real need to fear!</p>" +
              "<p>Plants have natural microorganisms that exist around them just like we have within our bodies and on our skin. 🌿</p>" +
              "<p>The unwanted visitor is actually a harmless saprophytic fungus. Although fuzzy, white mold on the soil may not hurt your plants, but we still suggest removing it gently when you notice it.</p>" +
              "<p>👉 &nbsp; The first step is to take a few clean cotton swabs and use them to remove all the mold from the soil surface. Repeat the process if needed.</p>" +
              "<p><b>IMPORTANT:</b> Next time when you refill UrbiGo water tank, make sure that only the tip of the capsule is dipped in the water, not an entire UrbiGo capsule. Let the nano soil inside the capsule dry out a bit. This will discourage mold from growing.</p>" +
              "<p>In case you need any further assistance, we're here to help! &nbsp;💚</p>",
          "img": "/static/tab2.png"
        },
        {
          "category_id": 11,
          "title": "Pollination",
          "sub_title": "Doing bees’ works",
          "content": "<p>If you’re growing any type of flowering plant in your UrbiGo smart garden, there’s a good chance you’ll need to hand pollinate, as soon as the first flowers emerge.</p>" +
              "<p>In nature, pollinators like bees, animals or even wind, help reproduction in 90% of the world's flowering plants. Many of them we consume daily!</p>" +
              "<p>When bees are not around, learning and applying <b>hand pollination skills</b> will help your mini veggies bear more fruits, and herbs to release high quality seeds for the new indoor growing cycle.</p>" +
              "<p>The simplest of the hand pollination techniques is simply to <b>shake the plant</b>. This method is effective for self-pollinating plants like tomatoes & peppers.</p>" +
              "<p>To hand pollinate melons, strawberries, herbs and flowers, try using <b>a clean cotton swab</b> or tiny paint brush, <b>and brush every flower</b> gently. The pollen grains are sticky, and will be transferred easily from one flower to another.</p>",
          "img": "/static/tab28.png"
        },
        {
          "category_id": 0,
          "title": "Sowing guide",
          "sub_title": "how much seeds UrbiGo capsule need?",
          "content": "Not all seeds are created equal. Some plant species have higher sprouting rates than others. Even within a single plant type, some of the seeds may be older than others, which is causing them to grow much slower than their buddies.<br/><br/>For the majority of the plants grown in UrbiGo smart garden, the germination rate is about 95%. Around 15 seeds are enough per one capsule. Avoid planting only one seed as the space in the UrbiGo capsule is designed to support multiple shoots.",
          "img": "/static/tab4.png"
        },
        {
          "category_id": 0,
          "title": "My plants growth pace is different",
          "sub_title": "Peer pressure or a bug?",
          "content": "You may notice that one out of four plants in your UrbiGo grows slower than others. Although this could happen it is not a reason for the concern.<br/><br/>Some seeds may sprout slower than the others, causing the plants in particular UrbiGo capsule to be smaller than their peers.<br/><br/>If the plants look healthy, it means that everything is OK. If you notice some discoloration in the leaves, or they begin to dry, please <a href='/feedback/'>contact our team for the additional support.</a>",
          "img": "/static/tab6.png"
        },
        {
          "category_id": 0,
          "title": "Watering your digital pet",
          "sub_title": "Know what to expect as plants mature",
          "content": "Unlike the traditional pot, UrbiGo plant capsules are specially designed to maximize water usage, minimize drainage, while providing your plants with the optimal amount of liquid and nutrients for around 3 months.<br/><br/>At the seed stage, you can expect the water in your tank to last up to 2 weeks (this may vary upon the plants you're growing). As your plants mature, grow, and reach the lights the water consumption increases, and you should fill in the water tank every 3-5 days.<br/><br/>Worry not, because your UrbiGo will in any case notify you when it needs more water!",
          "img": "/static/tab7.png"
        },
      ]
    }
  },
  methods: {
    open() {
      this.Dom7('.subnavbar').hide()
    },
    close() {
      this.Dom7('.subnavbar').show()
    }
  }
};
</script>

<style scoped>
/*.swiper-slide {*/
/*border-radius: 15px;*/
/*!*margin-left:10px;*!*/
/*!*margin-right: 10px;*!*/
/*}*/

.shadow {
  box-shadow: var(--f7-card-expandable-box-shadow);
  margin-left: calc(var(--f7-card-expandable-margin-horizontal) + var(--f7-safe-area-left));
  margin-right: calc(var(--f7-card-expandable-margin-horizontal) + var(--f7-safe-area-right));
}

.padding-b {
  padding-bottom: 10px;
}

.navbar-hidden .subnavbar {
  pointer-events: auto;
}

.icon-class {
  border: 1px solid #e3e3e3;
  padding: 5px;
  border-radius: 7px;
}
</style>


<!--
    <f7-block-title medium>Device Info</f7-block-title>
    <f7-card
      style="background: url(/static/tab21.png) 0% 100% / cover; color: #fff; font-size: 25px;"
      content="Water level getting low. Quench your plant's thirst!">
    </f7-card>

    <f7-card
      style="background: url(/static/tab22.png) 0% 100% / cover; color: #fff; font-size: 25px;"
      content="Understand UrbiGo's temperature indicator">
    </f7-card>

    <f7-card
      style="background: url(/static/tab23.png) 0% 100% / cover; color: #fff; font-size: 25px;"
      content="Time to lift lights bar up!">
    </f7-card>
-->


<!--
    <div data-pagination='{"el": ".swiper-pagination"}' style="padding: 10px 0;"
         data-space-between="10" data-slides-per-view="auto" data-centered-slides="true"
         class="swiper-container swiper-init demo-swiper-multiple demo-swiper-multiple-auto">

      <div class="swiper-wrapper">
        <div class="swiper-slide elevation-2" style="background: url(/static/tab21.png) 0% 100% / cover;">
          <span class="title">Water level getting low</span><br/>
          <span class="description">quench your plant's thirst!</span>
        </div>

        <div class="swiper-slide elevation-2" style="background: url(/static/tab22.png) 0% 100% / cover;">
          <span class="title">Understand UrbiGo's temperature indicator</span><br/>
          <span class="description"></span>
        </div>

        <div class="swiper-slide elevation-2" style="background: url(/static/tab23.png) 0% 100% / cover;">
          <span class="title">Time to lift lights bar up!</span><br/>
          <span class="description"></span>
        </div>

        <div class="swiper-slide elevation-2" style="background: url(/static/tab24.png) 0% 100% / cover;">
          <span class="title">UrbiGo growing lights</span><br/>
          <span class="description">and why plants love them</span>
        </div>

        <div class="swiper-slide elevation-2" style="background: url(/static/tab25.png) 0% 100% / cover;">
          <span class="title">Clean your water tank</span><br/>
          <span class="description">a simple three-step guide</span>
        </div>

        <div class="swiper-slide elevation-2" style="color:#000;">
          <span class="title">Your plants grown up</span><br/>
          <span class="description">it's time for new refill</span>
        </div>
      </div>
    </div>
    -->

<!--
<f7-block-title>Growing Tips 3</f7-block-title>
<div data-pagination='{"el": ".swiper-pagination"}' data-space-between="10" style="padding: 10px 0;"
     data-slides-per-view="3" class="swiper-container swiper-init demo-swiper-multiple">

  <div class="swiper-wrapper">
    <div style="visibility: hidden; margin-left: 16px;"></div>
    <div class="swiper-slide elevation-2" style="background-color: #5dbefe;">Slide 1</div>
    <div class="swiper-slide elevation-2" style="background-color: #ffb6ac;">Slide 3</div>
    <div class="swiper-slide elevation-2" style="background-color: #9b7e7d;">Slide 4</div>
    <div class="swiper-slide elevation-2" style="background-color: #a5bc98;">Slide 5</div>
    <div class="swiper-slide elevation-2" style="background-color: #fddb41;">Slide 6</div>
    <div class="swiper-slide elevation-2" style="background-color: #35abe0;">Slide 7</div>
    <div class="swiper-slide elevation-2" style="background-color: #5dbefe;">Slide 2</div>

    <div class="swiper-slide">Slide 8</div>
    <div class="swiper-slide">Slide 9</div>
    <div class="swiper-slide" style="visibility: hidden;"></div>
  </div>
</div>
-->

<!--
<f7-block-title>Growing Tips 2</f7-block-title>
<div data-pagination='{"el": ".swiper-pagination"}' data-space-between="10"
     data-slides-per-view="2" class="swiper-container swiper-init demo-swiper-multiple">
  <div class="swiper-wrapper">
    <div style="visibility: hidden; margin-left: 16px;"></div>
    <div class="swiper-slide">Slide 1</div>
    <div class="swiper-slide">Slide 2</div>
    <div class="swiper-slide">Slide 3</div>
    <div class="swiper-slide">Slide 4</div>
    <div class="swiper-slide">Slide 5</div>
    <div class="swiper-slide">Slide 6</div>
    <div class="swiper-slide">Slide 7</div>
    <div class="swiper-slide">Slide 8</div>
    <div class="swiper-slide">Slide 9</div>
    <div class="swiper-slide" style="visibility: hidden;"></div>
  </div>
</div>
-->