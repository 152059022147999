<template>
  <f7-page name="edit-garden">
    <f7-navbar title="Device Info" back-link="Learn"></f7-navbar>

    <f7-block-title large>Cleaning and <br/>maintenance</f7-block-title>

    <f7-block class="mb">
      Before installing a new set of UrbiGo capsules, please clean the water tank following this link: <br/><br/>

      <!--<iframe width="100%" height="300" src="https://www.youtube.com/embed/cMExeLNvRqw" frameborder="0"-->
      <!--allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->

    </f7-block>

    <f7-list class="inset" style="border:1px solid #e3e3e3;">
      <f7-list-item title="Go to YouTube video" link="https://www.youtube.com/embed/cMExeLNvRqw" external target="_blank" class="external">
        <f7-icon slot="media" f7="play_rectangle_fill" style="color: #FF0000;"></f7-icon>
      </f7-list-item>
    </f7-list>


  </f7-page>
</template>

<script>
  export default {
    data() {
      return {}
    },
    methods: {},
    created() {
    },
    beforeDestroy() {
    }

  }
</script>

<style scoped>
  .mtb {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .mb {
    margin-bottom: 20px !important;
  }
</style>