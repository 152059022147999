<template>
  <f7-page name="tab4">
    <f7-navbar transparent title="Settings"></f7-navbar>
    <f7-block-title class="like-title">Settings</f7-block-title>

    <f7-list v-if="this.$root.getCurrent()">
      <f7-list-item link="/edit-garden/"> {{this.$root.getCurrent().name}}
        <f7-icon slot="media" f7="gear" color="white" style="background-color: #62bb48;"></f7-icon>
      </f7-list-item>
    </f7-list>

    <f7-block-title>Help</f7-block-title>
    <f7-list>
      <f7-list-item title="Contact Support" link="/feedback/">
        <f7-icon slot="media" f7="chat_bubble_2_fill" color="white" style="background-color: #1f7bf3;"></f7-icon>
      </f7-list-item>
    </f7-list>

    <f7-block-title>Device(s)</f7-block-title>
    <f7-list>
      <f7-list-item title="Add UrbiGo device" link="/add-urbigo/">
        <f7-icon slot="media" f7="plus_circle" color="white" style="background-color: #539af8;"></f7-icon>
      </f7-list-item>

      <f7-list-item v-if="devices && devices.length" title="Set New Capsules" link="/reset-capsules/">
        <f7-icon slot="media" f7="goforward_plus" color="white" style="background-color: #34c758;"></f7-icon>
      </f7-list-item>
      <f7-list-item v-if="devices && devices.length" title="Change WiFi network" link="/unlink/">
        <f7-icon slot="media" f7="wifi_slash" color="white" style="background-color: #ff9500;"></f7-icon>
      </f7-list-item>
      <f7-list-item v-if="devices && devices.length" title="Remove Device" link="/delete/">
        <f7-icon slot="media" f7="trash" color="white" style="background-color: #ff2d55;"></f7-icon>
      </f7-list-item>
    </f7-list>

    <f7-block-title>Account</f7-block-title>
    <f7-list>
      <f7-list-item title="Profile" link="/profile/">
        <f7-icon slot="media" f7="person" color="white" style="background-color: #1f7bf3;"></f7-icon>
      </f7-list-item>
      <f7-list-item title="Change Password" link="/password/">
        <f7-icon slot="media" f7="lock_rotation" color="white" style="background-color: #5e9680;"></f7-icon>
      </f7-list-item>
      <f7-list-item title="Notifications" link="/notifications/">
        <f7-icon slot="media" f7="app_badge" color="white" style="background-color: #ff3c2f;"></f7-icon>
      </f7-list-item>
    </f7-list>

    <f7-block-title>More</f7-block-title>
    <f7-list>
      <f7-list-item title="About Us" link="/about-us/">
        <f7-icon slot="media" f7="info_circle" color="white" style="background-color: #1f7bf3;"></f7-icon>
      </f7-list-item>
      <f7-list-item title="Manual Connection" link="/read-manual/">
        <f7-icon slot="media" f7="doc_text" color="white" style="background-color: #8e8d92;"></f7-icon>
      </f7-list-item>
    </f7-list>

    <f7-block>
      <f7-block-header class="text-align-center">Made with ❤ by UrbiGo team</f7-block-header>
    </f7-block>
  </f7-page>
</template>

<script>
  export default {
    data() {
      return {
        devices: null,
      }
    },
    created() {
      this.devices = this.$root.getDevices()
    },

  };
</script>

<style scoped>
  .icon {
    border: 0px solid #f05e2c;
    border-radius: 5px;
    padding: 6px;

    font-size: 18px;
    width: 18px;
    height: 18px;
  }
</style>