<template>
  <f7-page>
    <f7-navbar title="Notifications" back-link="Home"></f7-navbar>
    <f7-block v-if="this.$root.notifications == null" class="row demo-preloaders align-items-stretch text-align-center">
      <f7-col class="border-loader">
        <f7-preloader :size="44"></f7-preloader>
      </f7-col>
    </f7-block>

    <f7-block v-else-if="this.$root.notifications.length == 0" class="text-align-center">
      <p style="font-size: 28px;">No notifications</p>
    </f7-block>

    <f7-list v-else class="no-margin" media-list>
      <f7-list-item
        v-for="(noty) in this.$root.notifications"
        :link="buildLink(noty.category_id)"
        :after="noty.day"
        @click="buildClick(noty.category_id)">

        <div slot="title">{{noty.title}}</div>
        <div slot="text">{{noty.text}}</div>

        <!--<img slot="media" src="https://cdn.framework7.io/placeholder/people-160x160-1.jpg" width="80" />-->
        <f7-icon slot="media" :f7="getIcon(noty.category_id)" :style="getColor(noty.category_id)" color="white"></f7-icon>
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
  export default {
    name: "",
    data() {
      return {}
    },
    created() {
      // @todo send req. only if unred is not 0
      this.$http.put('notifications/0').then(
        response => {
        },
        error => {
          this.$root.populateErrors('Error', error.data.message)
        }
      )

    },
    methods: {
      buildClick(categoryId) {
        if (categoryId == 6) {
          this.$f7.tab.show('#tab-3')
        }
        else if (categoryId == 8 || categoryId == 9 || categoryId == 10) {
          this.$f7.tab.show('#tab-2')
          this.Dom7('#expendable' + categoryId).click()
        }
      },
      buildLink(categoryId) {
        let CATEGORIES = {
          1: '/noti-welcome/',
          2: '/noti-lights-on/',
          3: '/noti-manual-lights/',
          4: '/noti-water-warning/',
          5: '/noti-remove-transparent-domes/',
          6: '#',
          7: '/lid/',
          8: '#',
          9: '#',
          10: '#',
          11: '/feature-notifications/',
          12: '/pull-down-lights/',
          13: '/highest-light-bar-level/'
        }

        if (CATEGORIES[categoryId]) {
          return CATEGORIES[categoryId]
        }

        return false
      },
      getIcon(categoryId) {
        let ICONS = {
          1: 'heart',
          2: 'stopwatch',
          3: 'light_max',
          4: 'drop',
          5: 'arrow_merge',
          6: 'camera_fill',
          7: 'arrow_branch',
          8: 'burst_fill',
          9: 'scissors',
          10: 'wand_rays',
          11: 'bell',
          12: 'arrow_down',
          13: 'leaf_arrow_circlepath',
        }

        if (ICONS[categoryId]) {
          return ICONS[categoryId]
        }

        return 'checkmark_alt'
      },
      getColor(categoryId) {
        let ICONS = {
          1: 'background-color: #34c758',
          2: 'background-color: #62bb48',
          3: 'background-color: #ff9500',
          4: 'background-color: #1f7bf3',
          5: 'background-color: #18a360',
          6: 'background-color: #207bf3',
          7: 'background-color: #18a360',
          8: 'background-color: #6c7b89',
          9: 'background-color: #f27934',
          10: 'background-color: #28ae60',
          11: 'background-color: #34c758',
          12: 'background-color: #45A15B',
          13: 'background-color: #45A15B',
        }

        if (ICONS[categoryId]) {
          return ICONS[categoryId]
        }

        return 'background-color: #cdcdcd;'
      }

    }
  }
</script>

<style scoped>
  .icon {
    border-radius: 18px;
    padding: 6px;
    font-size: 22px;
    width: 22px;
    height: 22px;
    top: 5px;
  }

  img {
    border-radius: 30px;
    padding: 6px;
    width: 34px;
    height: 34px;
    top: 5px;
  }
</style>