<template>
  <f7-page>
    <f7-navbar title="Tips" back-link="Back"></f7-navbar>

    <f7-block class="">
      <h2 medium class="text-align-center">Time to remove transparent domes!</h2>
      <p>Yey! A new stage in the life of your plants has just begun.</p>
      <p>They are not helpless babies anymore and are ready to leave their transparent covers.</p>
      <p>If your plants have reached the inner top of the transparent dome, it’s time to remove it and let them grow happy and free.</p>
    </f7-block>
  </f7-page>
</template>

<script>
  export default {
    data() {
      return {}
    },
    methods: {},
    mounted() {
    }
  }
</script>
<style scoped>
</style>