<template>
  <f7-page>
    <f7-navbar title="Welcome 👋" back-link="Back"></f7-navbar>
    <f7-block inset>
      <h2 medium class="text-align-center">Welcome to<br/> the green community!</h2>
      <p>Congrats! You’ve just made one small step for humanity but a BIG ONE for Nature. 💚</p>
      <p>We’re on a mission to make gardening “cool” again, fun & easy for you. And, thanks to you, the projections for the future now look a lot more optimistic.
      </p>
      <p>UrbiGo will take you on an exciting urban gardening journey, day to day, no matter the season or weather. Starting today!</p>
      <p>We are so grateful for having YOU in our funky & green community! 🙏</p>
      <p>Lots of love, <br/>UrbiGo Team</p>
    </f7-block>
  </f7-page>
</template>

<script>
  export default {
    data() {
      return {}
    },
    methods: {},
    mounted() {
    }
  }
</script>

<style scoped>

</style>