<template>
  <f7-page name="edit-garden">
    <f7-navbar title="Device Info" back-link="Learn"></f7-navbar>

    <f7-block-title large>Temperature sensor</f7-block-title>

    <f7-block class="mb">
      The temperature sensor in your UrbiGo is actually a mini hardware heat monitor.
    </f7-block>

    <f7-block class="mtb">
      It doesn't show the room temperature but the temperature of UrbiGo's mini plant computer nested in the base unit of your device.
    </f7-block>

    <f7-block class="mtb">
      We monitor hardware temperature to make sure your smart nano garden works smoothly and there's no overheating that could interfere with normal functioning.
    </f7-block>
  </f7-page>


</template>

<script>
  export default {
    data() {
      return {}
    },
    methods: {
      save() {
      }
    },
    created() {
    },
    beforeDestroy() {
    }

  }
</script>

<style scoped>
  .mtb {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .mb {
    margin-bottom: 20px !important;
  }
</style>