<template>
  <f7-page>
    <f7-navbar title="Profile" back-link="Settings"></f7-navbar>

    <f7-block class="text-align-center">

      <!--<img src="/static/alex.jpeg" class="profile-img"/>-->

      <f7-block-title class="title-class">{{ user.first_name }} {{ user.last_name }}</f7-block-title>
      <f7-block>
        <f7-block-header>{{ user.email }}</f7-block-header>
      </f7-block>
    </f7-block>

    <f7-list no-hairlines-md @change="checkData">
      <f7-list-input
          label="First Name"
          type="text"
          :placeholder="placeholder_first_name"
          :value="user.first_name"
          :info="error_first_name"
          @input="user.first_name = $event.target.value; checkData()">
      </f7-list-input>

      <f7-list-input
          label="Last Name"
          type="text"
          :placeholder="placeholder_last_name"
          :value="user.last_name"
          :info="error_last_name"
          @input="user.last_name = $event.target.value; checkData()">

      </f7-list-input>

      <f7-list-input label="Country" type="select" :value="user.country_code"
                     @input="user.country_code = $event.target.value;">
        <option v-for="(value, key) in $root.countries" :value="key">{{ value }}</option>
      </f7-list-input>

      <f7-list-input label="Birthday" type="date" :value="user.birth_date"
                     @input="user.birth_date = $event.target.value"></f7-list-input>
    </f7-list>

    <f7-list>
      <f7-list-button @click="logout">Sign Out</f7-list-button>
    </f7-list>

    <f7-list>
      <f7-list-button color="red" @click="deleteAccountConfirm">Request Delete Account</f7-list-button>
    </f7-list>


  </f7-page>
</template>

<script>
export default {
  data() {
    return {
      user: {},
      placeholder_first_name: '',
      placeholder_last_name: '',
      error_first_name: '',
      error_last_name: '',
    }
  },
  methods: {
    checkData() {
      this.error_first_name = (this.user.first_name == '') ? "Value is required and can't be empty" : ''
      this.error_last_name = (this.user.last_name == '') ? "Value is required and can't be empty" : ''
    },
    logout() {
      // somehow we need to delete all the data. @todo
      this.$root.collect('USER_LOGOUT')

      localStorage.clear()
      this.$f7router.navigate('/login')
    },
    deleteAccountConfirm() {
      const app = this.$f7;

      app.dialog.prompt("We're sorry to see you go!<br/>" +
          "Please share with us the reason for deleting your account?<br/>" +
          "It's not mandatory, but we appreciate your feedback.",
          "Delete Account", (feedback) => {
            this.deleteAccount(feedback)
          })
    },
    deleteAccount(feedback) {
      let message = 'DELETE ACCOUNT PLEASE.\r\n' + 'Feedback:' + feedback;

      this.$http.post('feedback', {message: message}).then(
          response => {
            this.$root.showNotification('Thank you!', 'We will process your request in the next 24h and send you an email when we delete your account with all your data.')
          },
          error => {
            this.$root.showNotification('Something went wrong.', error.data.message)
          }
      )
    },
    saveProfile() {
      var data = {
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        country_code: this.user.country_code,
        birth_date: this.user.birth_date
      };

      this.$root.$http.post('profile', data).then(
          response => {
            // all good
          },
          error => {
            this.$root.populateErrors("Can't save profile", error.data.message)
          }
      )
    },

  },
  created() {
    this.user = this.$root.getUser()
    this.placeholder_first_name = this.user.first_name
    this.placeholder_last_name = this.user.last_name
  },

  // this will not save the date if tab is changed, only if user go back (aka click on a settings)
  beforeDestroy() {
    this.saveProfile()
  },
}
</script>

<style scoped>
.profile-img {
  width: 200px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
}

.title-class {
  margin-bottom: 0px;
  margin-top: 5px;
}
</style>