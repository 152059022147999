<template>
  <f7-page>
    <f7-navbar title="Water Level Debug" back-link="Back"></f7-navbar>
    <f7-block class="">
      <p>The position of the water tank inside the device may be slightly disturbed due to transportation (img 1).</p>
      <p>
        You can solve this in a one simple steps:<br/>
        Lift the capsule holder together with all 4 capsules and gently move the water tank so that it rests on the
        water level sensor as shown
      </p>
    </f7-block>
    <f7-block>
      <img src="/static/notifications/water_debug_1.png" style="width:49%; border:1px solid #d3d3d8;">
      <img src="/static/notifications/water_debug_2.png" style="width:49%; border:1px solid #d3d3d8; float:right;">
    </f7-block>

    <f7-block class="">
      <p>
        Your UrbiGo should read the water lever. If the problem persists after several attempts,
        please contact our team.
      </p>
    </f7-block>

    <f7-list style="">
      <f7-list-item title="Contact our team" link="/feedback/">
        <f7-icon slot="media" f7="envelope_circle" style="color:#5856d5;;"></f7-icon>
      </f7-list-item>
    </f7-list>

  </f7-page>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {},
  mounted() {
  }
}
</script>

<style scoped>

</style>