<template>
  <f7-page>

    <f7-navbar transparent title="Community">
    </f7-navbar>

    <f7-block-title class="like-title" style="text-align: center;">Community</f7-block-title>

    <f7-block style="text-align: center;">
      <p>Discover what other UrbiGrowers are growing<br/> and show off your mini garden!</p>

      <f7-link href="/add-to-wall/" icon-only class="elevation-5 photo-style">
        <f7-icon f7="camera"></f7-icon>
      </f7-link>
    </f7-block>

    <f7-card v-for="post in this.posts" class="demo-facebook-card ">
      <f7-card-header class="no-border">
        <div class="demo-facebook-avatar">
          <!--<img src="https://cdn.framework7.io/placeholder/people-68x68-1.jpg" width="34" height="34"/>-->
          <f7-icon f7="person_circle" size="37" style="color:#838383;"></f7-icon>
        </div>
        <div class="demo-facebook-name">{{post.display_name}}</div>
        <div class="demo-facebook-date">{{post.time}}h</div>
      </f7-card-header>

      <f7-card-content :padding="false">
        <img :src="getImgWebPath(post.path)" width="100%"/>
      </f7-card-content>

      <f7-card-footer class="no-border">
        {{post.description}}
      </f7-card-footer>
    </f7-card>

    <f7-block v-if="!posts" class="row demo-preloaders align-items-stretch text-align-center">
      <f7-col class="border-loader">
        <f7-preloader :size="44"></f7-preloader>
      </f7-col>
    </f7-block>


  </f7-page>
</template>

<script>

  export default {
    data() {
      return {
        posts: null,
      }
    },
    created() {
      this.$http.get('wall').then(
        response => {
          this.posts = response.data.data;
        },
        error => {
          this.$root.populateErrors("Error", error.data.message);
        }
      );
    },
    methods: {
      getImgWebPath(img) {
        let url = this.$http.options.root;
        url = url.substring(0, url.length - 4); // trim "/api" from url
        url = url + img;


        return url; // for test we will use old app, revert this later!
        //return 'https://app.urbigo.me' + img;
      }
    },


  };
</script>

<style scoped>
  .photo-style {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-color: rgb(31, 123, 243);
  }

  .photo-style i {
    font-size: 30px;
    color: #fff;
  }

  .border-loader {
    padding-top: 35px;
    border-top: 1px solid #d7d7d8;
  }
</style>