<template>
  <f7-page name="edit-garden">
    <f7-navbar title="Device Info" back-link="Learn"></f7-navbar>

    <f7-block-title large>Lifting of the Lid</f7-block-title>

    <f7-block class="mb">
      As soon as you notice that your greens have reached the lights, it's time to lift the UrbiGo lid all the way up.
    </f7-block>

    <f7-block class="mtb">
      Carefully pull UrbiGo lid up by holding its rare end with one hand and UrbiGo base firmly with the other.
    </f7-block>

    <f7-block class="mtb">
      If you do not lift the lid with the growing lights when notified, you risk burning your mini green oasis - and, frankly, nobody likes "fried basil pesto".
    </f7-block>

    <f7-block>
      <p class="text-align-center">
        <img src="/static/learn/lift_lid.gif">
      </p>
    </f7-block>

  </f7-page>
</template>

<script>
  export default {
    data() {
      return {}
    },
    methods: {},
    created() {
    },
    beforeDestroy() {
    }

  }
</script>

<style scoped>
  .mtb {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .mb {
    margin-bottom: 20px !important;
  }
</style>