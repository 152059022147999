export default {
  data() {
    return {
      devices: null,        // all user's devices
      loaded: false,        // check if content from the API is loaded
      deviceValues: {},     // values of current device
      deviceLoaded: false,  // check if values of device is loaded
      currentDevice: 0,     // index of current selected device on home page. If there is only one index would be 0
    }
  },
  methods: {
    initDevices(devices) {
      this.devices = devices;
    },
    hasDevice() {
      return this.devices.length ? true : false;
    },
    hasMultiple() {
      return (this.devices.length > 1) ? true : false
    },
    getCurrent() {
      if (this.devices[this.currentDevice]) {
        return this.devices[this.currentDevice]
      }

      return false
    },
    setCurrent(index) {
      this.currentDevice = index
    },
    setCurrentById(id) {
      for (let i = 0; i < this.devices.length; i++) {
        if (this.devices[i].id == id) {
          this.setCurrent(i)
          break
        }
      }
    },
    addDevice(device) {
      this.devices.unshift(device);
    },
    removeDevice(id) {
      // remove device by ID
      for (let i = 0; i < this.devices.length; i++) {
        if (this.devices[i].id == id) {
          this.devices.splice(i, 1)
          break
        }
      }
    },
    changeData(id, device) {
      for (let i = 0; i < this.devices.length; i++) {
        if (this.devices[i].id == id) {
          this.devices[i] = device
          break
        }
      }
    },
    getDevices() {
      return this.devices
    },
    updateLightsOnCurrentDevice(lights) {
      this.devices[this.currentDevice].daily_light_hours = lights
    }
  }
};