<template>
  <f7-page>
    <f7-navbar title="New Capsules" back-link="Settings"></f7-navbar>

    <!-- First step -->
    <span v-if="!setPlants">
      <f7-block strong inset style="border: 1px solid #e5e5e5; padding-left: 0px;">
        <f7-row>
          <f7-col width="20" style="margin: auto; text-align: center;">
            <f7-icon f7="info_circle" size="38px" style="color: #34c758;"></f7-icon>
          </f7-col>
          <f7-col width="80">
            Place a new set of UrbiGo plant capsules and start the next growing cycle.
            The plant health data will be reset from the start.
          </f7-col>
        </f7-row>
      </f7-block>

      <f7-block-title v-if="(devices && devices.length)">Choose your device:</f7-block-title>
      <f7-list v-if="(devices && devices.length)">
        <f7-list-item v-for="(device) in devices" link="#"
                      @click="openConfirm(device.id)">{{ device.name }}</f7-list-item>
      </f7-list>
      <f7-block v-else strong>
        <p class="my-title">
          Well, there is no device on your account
        </p>
      </f7-block>
      <f7-block v-if="isSaving" class="row demo-preloaders align-items-stretch text-align-center">
        <f7-col>
          <f7-preloader :size="44"></f7-preloader>
          <p class="text-align-center">This may take a while...</p>
        </f7-col>
      </f7-block>
    </span>

    <!-- Second step - choose plants -->
    <span v-else>
      <f7-block style="text-align: center;">
        <f7-block-title medium>Let's get growing!</f7-block-title>
        <p>
          Select your plants for this growing cycle or click on "surprise us"
          if you'll be using  other seed of your choice.
        </p>
      </f7-block>

      <f7-block>
        <f7-row v-for="plants in this.$root.groupedPlants">
          <f7-col v-for="plant in plants">
            <p v-if="plant.title" class="ttl">{{ plant.title }}</p>
            <img v-if="plant.img" :src="plant.img" @click="choose(plant.id)" :class="classImg(plant.id)"/>
          </f7-col>
        </f7-row>
      </f7-block>

      <div class="login-screen-page" style="margin-bottom: 3em;">
        <f7-list class="no-margin">
          <f7-list-button animate class="login-screen-content" @click="saveDevice">Confirm</f7-list-button>
        </f7-list>
      </div>
    </span>

  </f7-page>
</template>

<script>
export default {
  data() {
    return {
      isSaving: false,
      devices: [],
      setPlants: false,
      chosen: [],
    }
  },
  methods: {
    choose(id) {
      if (this.chosen.includes(id)) {
        this.chosen = this.chosen.filter(item => item !== id)
      } else {
        this.chosen.push(id)
      }

    },
    classImg(id) {
      if (this.chosen.includes(id)) {
        return 'elevation-5'
      }
    },
    saveDevice() {
      let device = this.$root.getCurrent()
      let saveData = {device_id: device.device_id, name: device.name, plants: this.chosen}

      this.$http.put('green-cube/' + device.id, saveData).then(
          response => {
            this.$root.showNotification('Hooray!', "Let's grow on.")
            this.$f7router.back('/tab4/', {force: true})
            this.$f7.views.main.router.refreshPage()
            this.setPlants = false
            this.chosen = []
          },
          error => {
            this.$root.populateErrors('Ouch', error.data.message);
          }
      )
    },
    openConfirm(id) {
      const app = this.$f7;
      app.dialog.confirm('Are you sure you want start new cycle?', () => {
        this.resetCapsules(id)
      })
    },
    resetCapsules(id) {
      this.isSaving = true
      this.$http.get('green-cube/' + id + '/restart').then(
          response => {
            this.isSaving = false
            this.$root.changeData(id, response.data.data)

            // after reset the cycle, choose new plants
            this.$root.setCurrentById(id)
            this.setPlants = true
          },
          error => {
            this.isSaving = false
            this.$root.populateErrors('Ouch', error.data.message)
          }
      )
    }
  },
  created() {
    this.devices = this.$root.getDevices()
  }
}
</script>

<style scoped>
.ttl {
  text-align: center;
  font-weight: bold;
  width: 100%;
  max-width: 100px;
}

.col img {
  width: 100%;
  max-width: 100px;
  padding: 3px;
  border-radius: 15px;
  background-color: #fff;
}
</style>