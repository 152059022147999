<template>

  <f7-page>
    <f7-navbar title="Make post" back-link="Community"></f7-navbar>


    <f7-block v-if="image" style="text-align: center;">
      <img class="preload-img" v-bind:src="getImgWebPath()" @click="openUpload"/>
    </f7-block>
    <f7-block v-else style="text-align: center;">
      <f7-link href="#" class="elevation-5 photo-style" @click="openUpload">
        <f7-icon f7="camera"></f7-icon>
      </f7-link>
    </f7-block>

    <!--<f7-block-title>Short description</f7-block-title>-->
    <f7-list no-hairlines-md>
      <f7-list-input
        clear-button
        type="textarea"
        placeholder="Short description"
        :value="description"
        @input="description = $event.target.value">
      </f7-list-input>
    </f7-list>

    <div class="login-screen-page">
      <f7-list class="no-margin">
        <f7-list-button animate class="login-screen-content" @click="publishPost">Publish your post</f7-list-button>
      </f7-list>
    </div>

    <input type="file" style="visibility: hidden;" name="image" id="file-input" @change="prepareImage"/>
  </f7-page>
</template>

<script>
  export default {
    data() {
      return {
        description: '',
        image: '',
        post_id: null
      }
    },
    methods: {
      openUpload() {
        document.getElementById("file-input").click()
      },
      prepareImage(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length) {
          return;
        }

        const app = this.$f7;
        app.dialog.preloader('Uploading');
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          this.uploadImage(reader.result);
        }
      },
      uploadImage(binaryData) {
        let data = {file: binaryData};
        const app = this.$f7;

        this.$http.post('wall/image', data).then(
          response => {
            this.image = response.data.data.path;
            this.post_id = response.data.data.post_id;
            app.dialog.close();
          },
          error => {
            this.$root.populateErrors('Error', error.data.message);
            app.dialog.close();
          }
        );
      },
      publishPost() {
        if (!this.image) {
          this.$root.showNotification('Image Missing', 'Please upload an image.');
          return;
        }
        else if (!this.description) {
          this.$root.showNotification('Description missing', 'Please write short description.');
          return;
        }
        else if (!this.post_id) {
          this.$root.showNotification('Error happen', 'Please try again or contact us for support.');
          return;
        }

        const app = this.$f7;
        app.dialog.preloader('Processing');

        var data = {post_id: this.post_id, description: this.description};
        this.$http.post('wall/publish', data).then(
          response => {
            app.dialog.close()
            this.$root.showNotification('Yeah!', 'You publish your post to the others!')
            this.$f7router.back('/tab3/', {force: true})
          },
          error => {
            app.dialog.close()
            this.$root.populateErrors('Strange error', error.data.message)
          }
        );
      },
      getImgWebPath() {
        let url = this.$http.options.root;
        url = url.substring(0, url.length - 4);
        url = url + this.image;

        return url;
      }
    },
    mounted() {
      this.openUpload()
    }
  }
</script>

<style scoped>
  .photo-style {
    width: 150px;
    height: 150px;
    border-radius: 75px;
    background-color: rgb(31, 123, 243);
  }

  .photo-style i {
    font-size: 75px;
    color: #fff;
  }

  .preload-img {
    width: 200px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
  }

</style>