<template>
  <f7-page>
    <f7-navbar title="Connect" back-link="Settings"></f7-navbar>

    <f7-block style="text-align: center;" v-if="!isConnected">
      <f7-block-title medium>Add UrbiGo</f7-block-title>
      <p>If your UrbiGo is already connected just enter UrbiGo device ID.
        To connect <b>more than one</b> devices please run connection wizard.</p>
    </f7-block>
    <f7-block style="text-align: center;" v-else>
      <f7-block-title medium>Awesome!</f7-block-title>
      <p>Your garden is connected. Now you just need to give you garden a name, and select plants you will grow.</p>
    </f7-block>

    <!-- first step -->
    <f7-list no-hairlines-md v-if="!isConnected">
      <f7-list-input
          clear-button
          type="text"
          placeholder="Enter UrbiGo device ID"
          :value="deviceId"
          @input="deviceId = $event.target.value"
      ></f7-list-input>
    </f7-list>
    <div v-if="!isConnected" class="login-screen-page">
      <f7-list class="no-margin">
        <f7-list-button animate class="login-screen-content" @click="checkDevice">Add device ID</f7-list-button>
      </f7-list>
    </div>

    <f7-list v-if="!isConnected" style='background-color:#f2f2f7;'>
      <f7-list-item title='Run connection wizard' link='/setup-device/'>
        <f7-icon slot='media' f7='antenna_radiowaves_left_right' style='color: #6ec057;'></f7-icon>
      </f7-list-item>
    </f7-list>

    <!-- second step -->
    <f7-list no-hairlines-md v-if="isConnected">
      <f7-list-input
          clear-button
          type="text"
          placeholder="Enter UrbiGo name"
          :value="name"
          @input="name = $event.target.value"
      ></f7-list-input>
    </f7-list>

    <f7-block v-if="isConnected">
      <f7-row v-for="plants in this.$root.groupedPlants">
        <f7-col v-for="plant in plants">
          <p v-if="plant.title" class="ttl">{{ plant.title }}</p>
          <img v-if="plant.img" :src="plant.img" @click="choose(plant.id)" :class="classImg(plant.id)"/>
        </f7-col>
      </f7-row>
    </f7-block>

    <div v-if="isConnected" class="login-screen-page" style="margin-bottom: 3em;">
      <f7-list class="no-margin">
        <f7-list-button animate class="login-screen-content" @click="saveDevice">Confirm</f7-list-button>
      </f7-list>
    </div>
  </f7-page>

</template>

<script>
export default {
  data() {
    return {
      chosen: [],
      deviceId: '',
      isConnected: false,
      name: '',
    };
  },
  mounted() {
  },
  methods: {
    choose(id) {
      if (this.chosen.includes(id)) {
        this.chosen = this.chosen.filter(item => item !== id)
      } else {
        this.chosen.push(id)
      }

    },
    classImg(id) {
      if (this.chosen.includes(id)) {
        return 'elevation-5'
      }
    },
    checkDevice() {
      const app = this.$f7;
      app.dialog.preloader('Connecting');

      this.$http.get('utilities/check-device', {params: {device_id: this.deviceId}}).then(
          response => {
            app.dialog.close();

            if (response.data.data.connected) {
              this.isConnected = true;
              //this.$root.showNotification('Awesome', 'Your garden is connected! Please provide a name of your garden to continue.');
            } else {
              this.$root.showNotification('Not connected', 'Seems like your UrbiGo garden is not connected to the WiFi. Please try again.');
            }
          },
          error => {
            app.dialog.close();
            this.$root.populateErrors('Ouch', error.data.message);
          }
      );
    },
    saveDevice() {
      const app = this.$f7;
      app.dialog.preloader('Connecting');

      this.$http.post('green-cube', {device_id: this.deviceId, name: this.name, plants: this.chosen}).then(
          response => {
            app.dialog.close();
            this.$root.addDevice(response.data.data);
            location.href = "/"
            // maybe change in a future, f7 loading everything...
            // this.$root.showNotification('Awww', 'You have successfully completed the setup of your UrbiGo.')
          },
          error => {
            app.dialog.close();
            this.$root.populateErrors('Ouch', error.data.message);
          }
      )
    }
  }
}
</script>

<style scoped>
.ttl {
  text-align: center;
  font-weight: bold;
  width: 100%;
  max-width: 100px;
}

.col img {
  width: 100%;
  max-width: 100px;
  padding: 3px;
  border-radius: 15px;
  background-color: #fff;
}
</style>