<template>

  <f7-page>
    <f7-navbar title="Disconnect WiFi" back-link="Settings"></f7-navbar>

    <f7-block strong inset style="border: 1px solid #e5e5e5; padding-left: 0px;">
      <f7-row>
        <f7-col width="20" style="margin: auto; text-align: center;">
          <f7-icon f7="info_circle" size="38px" style="color: #ff9500;"></f7-icon>
        </f7-col>

        <f7-col width="80">
          This action will disconnect your device from the current WiFi network.
          Use it only to change the network your UrbiGo is connected to.
        </f7-col>
      </f7-row>
    </f7-block>

    <f7-block-title v-if="(devices && devices.length)">Choose your device:</f7-block-title>
    <f7-list v-if="(devices && devices.length)">
      <f7-list-item v-for="(device) in devices" link="#" @click="openConfirm(device.id)">{{device.name}}</f7-list-item>
    </f7-list>

    <f7-block v-else strong>
      <p class="my-title">
        Well, there is no device on your account
      </p>
    </f7-block>

    <f7-block v-if="isSaving" class="row demo-preloaders align-items-stretch text-align-center">
      <f7-col>
        <f7-preloader :size="44"></f7-preloader>
        <p class="text-align-center">This may take a while...</p>
      </f7-col>
    </f7-block>
  </f7-page>
</template>

<script>
  export default {
    data() {
      return {
        isSaving: false,
        devices: [],
      }
    },
    methods: {
      openConfirm(id) {
        const app = this.$f7;
        app.dialog.confirm('Are you sure you want to unlink the device?', () => {
          this.unlinkDevice(id)
        })
      },
      unlinkDevice(id) {
        this.isSaving = true

        this.$http.get('actions/unlink', {params: {green_cube_id: id}}).then(
          response => {
            this.checkNewStatus(id)
          },
          error => {
            this.$root.populateErrors("Error", error.data.message)
            this.isSaving = false
          }
        )
      },
      checkNewStatus(id) {
        this.$http.get('green-cube/' + id + '/status').then(
          response => {
            this.$root.deviceValues = response.data.data;
            this.isSaving = false
            this.$root.showNotification('Success', 'You device is successfully unlinked from current WiFI.')
          },
          error => {
            this.$root.populateErrors('Ouch', error.data.message)
            this.isSaving = false
          }
        )
      }
    },
    created() {
      this.devices = this.$root.getDevices()
    }
  }
</script>

<style scoped>
</style>