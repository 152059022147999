<template>

  <f7-page>
    <f7-navbar title="Light tips" back-link="Back"></f7-navbar>


    <f7-block class="">
      <h2 medium class="text-align-center">Put down the lights</h2>
      <p>Make sure you’ve put UrbiGo lights down, after planting the seeds, as shown below. This way plants will get an optimum amount needed for sprouting.</p>
    </f7-block>

    <f7-block>
      <p class="text-align-center">
        <img src="/static/notifications/reverse.gif">
      </p>
    </f7-block>
  </f7-page>
</template>

<script>
  export default {
    data() {
      return {}
    },
    methods: {},
    mounted() {
    }
  }
</script>

<style scoped>

</style>