export default {
  data() {
    return {
      errorMessages: [],
      successMessage: {
        title: '',
        text: '',
      },
      infoMessage: {
        title: '',
        text: '',
      },
      warningMessage: {
        title: '',
        text: '',
      }
    }
  },
  methods: {
    // Helper methods
    getErrorKey(key) {
      key = key.replace('_', ' ')
      key = key.charAt(0).toUpperCase() + key.slice(1)

      return key
    },
    getErrorMessage(values) {
      return values[Object.keys(values)[0]]
    },
    removeErrors() {
      this.errorMessages = []
    },
    // -----------------

    // populate and show errors from API responses
    populateErrors(title, messages) {
      if (typeof messages === 'string') {
        this.errorMessages = []
        this.errorMessages = {error: {one_error: messages}}
      }
      else {
        this.errorMessages = messages;
      }

      let notification = ''
      for (const [key, value] of Object.entries(this.errorMessages)) {
        let field = this.$root.getErrorKey(key)
        let message = this.$root.getErrorMessage(value)

        if (notification !== '') {
          notification += "<br/>"
        }

        notification += field + ': ' + message
      }

      this.showNotification(title, notification)
    },

    // show notification
    showNotification(title, text) {
      // Create toast
      this.notificationFull = this.$f7.notification.create({
        icon: '',
        title: '',
        titleRightText: '',
        subtitle: title,
        text: text,
        closeTimeout: 5000,
      });

      // Open it
      this.notificationFull.open()
      this.removeErrors()
    }
  }
};