<template>
  <f7-page name="edit-garden">
    <f7-navbar title="Device Info" back-link="Learn"></f7-navbar>

    <f7-block-title large>Water level sensor</f7-block-title>

    <f7-block class="mb">
      UrbiGo will notify you every time your plants need watering.
      If the water level is low it's time to add water.
    </f7-block>

    <f7-block class="mtb">
      Carefully take out one UrbiGo plant capsule from the device and pour 2-3 250ml glasses of plain tap water through the hole.
    </f7-block>

    <f7-block class="mtb">
      Refresh UrbiGo App and see the new water level stats.
      In case the water level is still low please contact our technical support at support@urbigo.me and we will help you asap.
    </f7-block>
  </f7-page>
</template>

<script>
  export default {
    data() {
      return {}
    },
    methods: {
      save() {
      }
    },
    created() {
    },
    beforeDestroy() {
    }

  }
</script>

<style scoped>
  .mtb {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .mb {
    margin-bottom: 20px !important;
  }
</style>