<template>

  <f7-page>
    <f7-navbar title="Light tips" back-link="Back"></f7-navbar>


    <f7-block class="">
      <h2 medium class="text-align-center">Manual light control is tricky</h2>

      <p>It’s best for your plants to keep automatic lights turned ON. That way you can be sure they will get an optimal amount of light every day.</p>
      <p>
        But, if you decide to control the lights yourself, that’s OK also - just make sure to provide your greens with at
        <strong>least 14-16 hours of light</strong> per day.
      </p>

    </f7-block>


  </f7-page>
</template>

<script>
  export default {
    data() {
      return {}
    },
    methods: {},
    mounted() {
    }
  }
</script>

<style scoped>

</style>