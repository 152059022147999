<template>
  <f7-page>
    <f7-navbar title="Support" back-link="Back"></f7-navbar>

    <f7-block-title medium>We're here to help &nbsp; 🌱</f7-block-title>

    <f7-block>
      <p>
        Type your question here and we'll answer asap.
        Our support is bilingual - both ENG or SRB are great.
      </p>
    </f7-block>

    <f7-list no-hairlines-md>
      <f7-list-input
        type="textarea"
        placeholder="Enter your message"
        :value="message"
        :info="error_message"
        @input="message = $event.target.value"
      ></f7-list-input>
    </f7-list>

    <div v-if="!processing" class="login-screen-page">
      <f7-list class="no-margin">
        <f7-list-button animate class="login-screen-content" @click="sendFeedback">Send Message</f7-list-button>
      </f7-list>
    </div>

    <f7-block v-else class="row demo-preloaders align-items-stretch text-align-center">
      <f7-col>
        <f7-preloader :size="44"></f7-preloader>
      </f7-col>
    </f7-block>

    <f7-block>
      <p class="text-align-center">
        Also, you can ping us via WhatsApp/Viber
      </p>
    </f7-block>

    <f7-block style="text-align: center;">
      <f7-link href="whatsapp://send?phone=+381607660089" icon-only external>
        <f7-icon class="fab fa-whatsapp" style="color: #25D366;"></f7-icon>
      </f7-link>
      <f7-link href="viber://chat/?number=+381607660089" icon-only external>
        <f7-icon class="fab fa-viber" style="color: #7360f2;"></f7-icon>
      </f7-link>
    </f7-block>

  </f7-page>
</template>

<script>
  export default {
    data() {
      return {
        message: '',
        error_message: '',
        processing: false
      }
    },
    methods: {
      sendFeedback() {
        this.processing = true
        this.$http.post('feedback', {message: this.message}).then(
          response => {
            this.processing = false
            this.$root.showNotification('Thank you!', 'Someone from our team will follow up soon via e-mail.')
            this.message = ''
            this.error_message = ''
          },
          error => {
            this.processing = false
            this.error_message = error.data.message
          }
        )
      }
    }
  }
</script>

<style scoped>
  .no-margin {
    margin: 0px;
  }

  .icon {
    border: 0px solid #f05e2c;
    padding: 0px 10px;
    font-size: 36px;
    width: 36px;
    height: 36px;
  }
</style>