<template>

  <f7-page>
    <f7-navbar title="Notifications" back-link="Settings"></f7-navbar>
    <f7-list>
      <f7-list-item
        checkbox
        title="System Notifications"
        name="system"
        :checked="notifications.system"
        @change="notifications.system = !notifications.system"></f7-list-item>

      <f7-list-item
        checkbox
        title="Device Messages"
        name="device_messages"
        :checked="notifications.device_messages"
        @change="notifications.device_messages = !notifications.device_messages"></f7-list-item>

      <f7-list-item
        checkbox
        title="New Features"
        name="new_features"
        :checked="notifications.new_features"
        @change="notifications.new_features = !notifications.new_features"></f7-list-item>

      <f7-list-item
        checkbox
        title="Community Activity"
        name="community_activity"
        :checked="notifications.community_activity"
        @change="notifications.community_activity = !notifications.community_activity"></f7-list-item>
    </f7-list>
  </f7-page>

</template>

<script>
  export default {
    data() {
      return {
        notifications: []
      }
    },
    methods: {
      save() {
        let data = {
          system: this.notifications.system,
          device_messages: this.notifications.device_messages,
          new_features: this.notifications.new_features,
          community_activity: this.notifications.community_activity,
        };

        this.$root.$http.post('user-notifications', data).then(
          response => {
            // all good
          },
          error => {
            this.$root.populateErrors("Can't save notifications", error.data.message)
          }
        )
      },

    },
    created() {
      this.notifications = this.$root.getUser().notifications
    },
    beforeDestroy() {
      this.save()
    },

  }
</script>

<style scoped>
</style>