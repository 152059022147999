<template>
  <f7-page no-toolbar no-navbar no-swipeback login-screen transition="f7-circle">
    <f7-login-screen-title>Welcome!</f7-login-screen-title>

    <f7-list form>
      <f7-list-input
        label="Email"
        type="text"
        placeholder="Your email"
        v-model="credentials.email"
        @input="credentials.email = $event.target.value"
      ></f7-list-input>

      <f7-list-input
        label="Password"
        type="password"
        placeholder="Your password"
        v-model="credentials.password"
        @input="credentials.password = $event.target.value"
      ></f7-list-input>

      <!-- just to fix missing line on the last input -->
      <f7-list-input style="display: none;" type="hidden"></f7-list-input>
    </f7-list>

    <f7-list>
      <f7-list-button @click="login">Log In</f7-list-button>

      <f7-block-footer style="margin-top: 3em;">
        Don't have an account? <br/>
        <f7-link href="/register">Create account</f7-link>
        <br/><br/>
        Or maybe you <f7-link href="/forgot-password">Forgot Password</f7-link>?
      </f7-block-footer>
    </f7-list>
  </f7-page>
</template>
<script>

  export default {
    data() {
      return {
        credentials: {
          email: '',
          password: ''
        }
      };
    },
    methods: {
      login() {
        // this!
        const app = this.$f7

        this.$http.post('auth', this.credentials)
        .then(
          response => {
            this.$root.initDevices(response.data.data.user.devices);
            this.$root.initUser(response.data.data.user);
            this.$root.setHeader(response.data.data.token);
            this.$root.initCountries();

            if (this.$root.hasDevice()) {
              location.href = "/"
              //this.$router.push('/pulse/');
            } else {
              location.href = "/"
              //this.$router.push('/no-device');
            }
          },
          error => {
            app.dialog.alert(error.data.message);
          }
        )
      },
    },
  };
</script>
<style scope>
  .simple-list li:after, .links-list a:after, .list .item-inner:after {
    width: 95%;
  }
</style>


