<template>

  <f7-page no-toolbar login-screen>
    <f7-navbar transparent title="" back-link="Sign In"></f7-navbar>

    <div v-if="!processing && !isCodeSent">
      <f7-login-screen-title>Forgot Password</f7-login-screen-title>
      <f7-block-footer>
        Please enter your email and we will send you the code to reset your password
      </f7-block-footer>

      <f7-list form>
        <f7-list-input
          label="Email"
          type="text"
          placeholder="john.doe@example.com"
          :value="email"
          @input="email = $event.target.value"
        ></f7-list-input>

        <!-- just to fix missing line on the last input -->
        <f7-list-input style="display: none;" type="hidden"></f7-list-input>
      </f7-list>

      <f7-list>
        <f7-list-button @click="sendResetCode">Send Code</f7-list-button>
      </f7-list>

      <br/>
      <f7-list style="margin-left: 1em; margin-right: 1em;">
        <f7-block-footer style="border-top:1px solid #e8e8ec; padding-top:1em;">
          Received reset password code?<br/>
          <f7-link @click="isCodeSent = true">Click here</f7-link>
        </f7-block-footer>
      </f7-list>
    </div>

    <f7-block v-else-if="processing" class="row demo-preloaders align-items-stretch text-align-center">
      <f7-col>
        <f7-preloader :size="42"></f7-preloader>
      </f7-col>
    </f7-block>

    <div v-else>
      <f7-block-footer>
        To reset your password please enter the code sent to your email and set the new password below.
      </f7-block-footer>

      <f7-list form>
        <f7-list-input
          label="Code from email"
          type="text"
          placeholder="Code"
          :value="new_password.code"
          @input="new_password.code = $event.target.value"
        ></f7-list-input>

        <f7-list-input
          label="New password"
          type="password"
          placeholder="********"
          :value="new_password.password"
          @input="new_password.password = $event.target.value"
        ></f7-list-input>

        <f7-list-input
          label="Repeat password"
          type="password"
          placeholder="********"
          :value="new_password.confirm_password"
          @input="new_password.confirm_password = $event.target.value"
        ></f7-list-input>

        <!-- just to fix missing line on the last input -->
        <f7-list-input style="display: none;" type="hidden"></f7-list-input>

      </f7-list>

      <f7-list>
        <f7-list-button @click="resetPassword">Reset Password</f7-list-button>
      </f7-list>
    </div>
  </f7-page>

</template>

<script>
  export default {
    data() {
      return {
        processing: false,
        isCodeSent: false,
        email: '',
        new_password: {
          code: '',
          password: '',
          confirm_password: ''
        }
      }
    },
    methods: {
      sendResetCode() {
        this.processing = true;
        this.$http.post('forgot-password', {email: this.email}).then(
          response => {
            this.processing = false;
            this.isCodeSent = true;

          },
          error => {
            this.processing = false;

            const app = this.$f7
            app.dialog.alert(error.data.message);
          }
        )
      },
      resetPassword() {

        this.$http.post('reset-password', this.new_password).then(
          response => {
            this.$root.initDevices(response.data.data.user.devices);
            this.$root.initUser(response.data.data.user);
            this.$root.setHeader(response.data.data.token);
            this.$root.initCountries();

            if (this.$root.hasDevice()) {
              location.href = "/";

              // this.$router.push('/pulse/');
            } else {
              location.href = "/";

              // this.$router.push('/no-device');
            }

            // Since we refresh the page this don't has effect
            // this.$root.showNotification('Password changed', 'You change your password successfully.')
          },
          error => {
            const app = this.$f7
            app.dialog.alert(error.data.message);
          }
        )
      }
    }
  }
</script>

<style scoped>
</style>