<template>
  <f7-page name="edit-garden">
    <f7-navbar title="Device Info" back-link="Learn"></f7-navbar>

    <f7-block-title large>Growing lights</f7-block-title>

    <f7-block class="mb">
      UrbiGo uses a unique set of custom made growing lights adjusted to the indoor growing of most herbs, spices, and some mini veggies.
    </f7-block>

    <f7-block class="mtb">
      They mimic natural Sunlight and contain blue, red, and white LED bulbs of the specific wavelength suited to boost your plant's growth.
    </f7-block>

    <f7-block class="mtb">
      UrbiGo lights consume 1/10the of energy comparing to the standard light bulb used in our households, which makes them highly energy-efficient.
    </f7-block>
  </f7-page>
</template>

<script>
  export default {
    data() {
      return {}
    },
    methods: {},
    created() {
    },
    beforeDestroy() {
    }

  }
</script>

<style scoped>
  .mtb {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .mb {
    margin-bottom: 20px !important;
  }
</style>