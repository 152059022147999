export default {
    data() {
        return {
            groupedPlants: [
                // Use the same ID as in the web shop!
                // And we need to add ID in th filter as wel! od 26 ...
                [
                    {title: 'Basil', img: '/static/plants/v2/basil_mix.jpg', id: 1},
                    {title: 'Parsley', img: '/static/plants/v2/french_parsely.jpg', id: 9},
                    {title: 'Lemon Balm', img: '/static/plants/v2/lemon_mint.jpg', id: 5},
                ],
                [
                    {title: 'Austrian Pine', img: '/static/plants/v2/black_pine.jpg', id: 26},
                    {title: 'Lettuce', img: '/static/plants/v2/baby_latuce.jpg', id: 3},
                    {title: 'Cherry', img: '/static/plants/v2/tomato_mix.jpg', id: 12},
                ],
                [
                    {title: 'Arugula', img: '/static/plants/v2/arugula.jpg', id: 7},
                    {title: 'Strawberry', img: '/static/plants/v2/strawberries.jpg', id: 22},
                    {title: 'Lavender', img: '/static/plants/v2/lavender.jpg', id: 6}
                ],
                [
                    {title: 'Pasta Mix', img: '/static/plants/v2/pasta_mix.jpg', id: 11},
                    {title: 'Hot chilli', img: '/static/plants/v2/exotic_chilli.jpg', id: 14},
                    {title: 'Dwarf Pea', img: '/static/plants/v2/snow_peas.jpg', id: 25},
                ],
                [
                    {title: 'Bonsai', img: '/static/plants/v2/bonsai.jpg', id: 23},
                    {title: 'Bok Choy', img: '/static/plants/v2/bok_choy.jpg', id: 27},
                    {title: 'Aroma Duo', img: '/static/plants/v2/aroma_duo.png', id: 13},
                ],
                [
                    {title: 'Thyme', img: '/static/plants/v2/thyme.jpg', id: 17},
                    {title: 'Experimental', img: '/static/plants/v2/experimental_set.jpg', id: 999},
                    {}
                ],
                [
                    {} // fix the UI
                ],
            ]
        }
    },
};