<template>
  <f7-page>
    <f7-navbar title="Light tips" back-link="Back"></f7-navbar>

    <f7-block class="">
      <h2 medium class="text-align-center">Turn the <br/>automatic lights ON</h2>
      <p>
        Hey there,<br/>
        we understand that UrbiGo can be a bit bright at times, but it’s best to turn AUTOMATIC LIGHTS ON.💡
      </p>
      <p>This way, UrbiGo will give the optimal amount of light to your plants every day and according to their growth stage.</p>
    </f7-block>

    <f7-list class="inset" style="border:1px solid #e3e3e3;">
      <f7-list-item title="Go back to home screen" @click="goBack()">
        <f7-icon slot="media" f7="lightbulb" style="color: #ff9500;"></f7-icon>
      </f7-list-item>
    </f7-list>

  </f7-page>
</template>

<script>
  export default {
    data() {
      return {}
    },
    methods: {
      goBack() {
        const app = this.$f7
        const view = app.views.current
        view.router.back(view.history[0], {force: true})
      }
    },
  }
</script>

<style scoped>

</style>