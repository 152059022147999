export default {
  data() {
    return {
      Vue: null,
    };
  },

  methods: {
    initHeader(Vue) {
      this.Vue = Vue;
      const token = localStorage.getItem('token');

      if (token) {
        Vue.http.headers.common['Authorization'] = 'Bearer ' + token;
      }
    },
    setHeader(token) {
      localStorage.setItem('token', token);

      this.Vue.http.headers.common['Authorization'] = 'Bearer ' + token;
    },
    removeHeader() {
      localStorage.clear();

      delete this.Vue.http.headers.common['Authorization'];
    },
    isLoggedIn() {
      return localStorage.getItem('token') ? true : false;
    }
  }
}
