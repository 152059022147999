// import Vue, F7 and F7-Vue
import Vue from 'vue';
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';
import VueResource from 'vue-resource'

// Sentry login errors
import * as Sentry from "@sentry/browser";
import {Integrations} from "@sentry/tracing";

// import global mixins
import MessagesHelper from '../mixins/MessagesHelper';
import Header from '../mixins/Header';
import Devices from '../mixins/Devices';
import User from '../mixins/User';
import Stat from '../mixins/Stat';
import Plants from '../mixins/Plants'

// import css
import 'framework7/css/framework7.bundle.css';
import '../css/icons.css';
import '../css/app.css';

// Import App Component
import App from '../components/app.vue';

// Init Framework7-Vue Plugin and Vue resources for Ajax requests
Framework7.use(Framework7Vue);
Vue.use(VueResource);

// -------------- Business logic
let host = location.protocol + '//' + location.hostname;
if (host == 'http://localhost') {
  host = 'http://urbigo-app.test';
}
else {
  host = 'https://app.urbigo.me';
}

Vue.http.options.root = host + '/api';

// interceptors .
Vue.http.interceptors.push((request, next) => {
  // request object

  next(response => {
    // response object

    if (response.status == '401') {
      // redirect to login (with message)
    }

    // response.data = 'modified response data';
  });
});
// --------------

// Log all production JS error to sentry
Sentry.init({
    dsn: "https://4a13761c0eab4136ad68c8e712b47d94@o1126174.ingest.sentry.io/6166897",
    integrations: [new Integrations.BrowserTracing()],
    environment: (host === 'https://app.urbigo.me' ? 'production' : 'development'),
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    sampleRate: 1.0
});

// Init App
new Vue({
  mixins: [MessagesHelper, Header, Devices, User, Stat, Plants],
  el: '#app',
  render: (h) => h(App),

  // Register App Component
  components: {
    app: App
  },
  errorCaptured(err, vm, info){
    let data = {error: err.toString(), info: info, stack: err.stack}
    this.$root.$http.post('utilities/error-collect', data)
  },

  // On first init take and set all the info
  created() {
    // we can not use F7 instance here :/ but we can use it in mounted()
    this.$root.initHeader(Vue);

    if (this.$root.isLoggedIn()) {
      this.$http.get('profile').then(
        response => {
          this.$root.initDevices(response.data.data.user.devices)
          this.$root.initUser(response.data.data.user)
          this.$root.initCountries()
          this.$root.loaded = true
          this.$root.collect('APP_LOADED')

          // set refresh on every 10s
          this.refresh()
          window.setInterval(() => {
            this.refresh()
          }, 10000)
        },
        error => {
          // @todo show error and log error on server
          localStorage.clear()
          location.href = "/"
        }
      )
    }
    else {
      // if user is not logged in we don't need to wait for the users content
      this.$root.loaded = true
    }
  },
  methods: {
    refresh() {
      let deviceId = null

      if (this.$root.getCurrent()) {
        deviceId = this.$root.getCurrent().device_id
      }

      this.$http.get('refresh', {params: {device_id: deviceId}}).then(
        response => {
          this.$root.setUnreadNotifications(response.data.data.unread_notifications)

          if (response.data.data.light_hours) {
            this.$root.updateLightsOnCurrentDevice(response.data.data.light_hours)
          }
        },
        error => {
          // console.log(error.data)
        }
      )
    }

  }
});