<template>

  <f7-page>
    <f7-navbar title="Remove Device" back-link="Settings"></f7-navbar>

    <f7-block strong inset style="border: 1px solid #e5e5e5; padding-left: 0px;">
      <f7-row>
        <f7-col width="20" style="margin: auto; text-align: center;">
          <f7-icon f7="info_circle" size="38px" style="color: #ff2d55;"></f7-icon>
        </f7-col>

        <f7-col width="80">
          This action will permanently remove your device.
        </f7-col>
      </f7-row>
    </f7-block>

    <f7-block-title v-if="(devices && devices.length)">Choose your device:</f7-block-title>
    <f7-list v-if="(devices && devices.length)">
      <f7-list-item v-for="(device) in devices" link="#" @click="openConfirm(device.id)">{{device.name}}</f7-list-item>
    </f7-list>

    <f7-block v-else strong>
      <p class="my-title">
        Well, there is no device on your account to remove
      </p>
    </f7-block>

  </f7-page>
</template>

<script>
  export default {
    name: "Delete",
    data() {
      return {
        devices: [],
      }
    },

    methods: {
      openConfirm(id) {
        const app = this.$f7;
        app.dialog.confirm('Are you sure you want to remove the device?', () => {
          this.deleteDevice(id)
        })
      },
      deleteDevice(id) {
        const app = this.$f7;
        app.dialog.preloader('Removing');

        this.$http.delete('green-cube/' + id).then(
          response => {
            this.$root.removeDevice(id)
            app.dialog.close();

            if (!this.$root.getDevices().length) {
              location.href = "/"
            }
          },
          error => {
            app.dialog.close();
            this.$root.populateErrors('Error', error.data.message)
          }
        )
      },
    },
    created() {
      this.devices = this.$root.getDevices()
    },

  }
</script>

<style scoped>
</style>