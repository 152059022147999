<template>

  <f7-page no-toolbar login-screen>
    <f7-navbar transparent title="" back-link="Register"></f7-navbar>

    <f7-block-title medium>Mobile Application <br/>Terms and Conditions</f7-block-title>

    <f7-block-title></f7-block-title>

    <f7-block>
    </f7-block>


    <!-- -->

    <f7-block-title>AGREEMENT TO TERMS</f7-block-title>
    <f7-block>
      <p>
        These Terms and Conditions constitute a legally binding agreement made between you, whether
        personally or on behalf of an entity (“you”) and UrbiGo (“we,” “us” or “our”), concerning your
        access to and use of our mobile application (the “Application”). You agree that by accessing the
        Application, you have read, understood, and agree to be bound by all of these Terms and
        Conditions Use. If you do not agree with all of these terms and conditions,
        then you are expressly prohibited from using the application and you must discontinue use immediately.
      </p>
      <p>
        Supplemental terms and conditions or documents that may be posted on the Application from
        time to time are hereby expressly incorporated herein by reference. We reserve the right, in our
        sole discretion, to make changes or modifications to these Terms and Conditions at any time
        and for any reason. We will alert you about any changes by updating the “Last Updated” date of
        these Terms and Conditions and you waive any right to receive specific notice of each such
        change. It is your responsibility to periodically review these Terms and Conditions to stay
        informed of updates.
      </p>
      <p>
        The information provided on the Application is not intended for distribution to or use by any
        person or entity in any jurisdiction or country where such distribution or use would be contrary
        to law or regulation or which would subject us to any registration requirement within such
        jurisdiction or country. Accordingly, those persons who choose to access the Application from
        other locations do so on their own initiative and are solely responsible for compliance with local
        laws, if and to the extent local laws are applicable.
      </p>
      <p>
        The Application is intended for users who are at least 13 years of age. All users who are minors
        in the jurisdiction in which they reside (generally under the age of 18) must have the permission
        of, and be directly supervised by, their parent or guardian to use the Application. If you are a
        minor, you must have your parent or guardian read and agree to these Terms of Use prior to you
        using the Application.
      </p>
    </f7-block>


    <f7-block-title>INTELLECTUAL PROPERTY RIGHTS</f7-block-title>
    <f7-block>
      <p>
        Unless otherwise indicated, the Application is our proprietary property and all source code,
        databases, functionality, software, website designs, audio, video, text, photographs, and
        graphics on the Application (collectively, the “Content”) and the trademarks, service marks, and
        logos contained therein (the “Marks”) are owned or controlled by us or licensed to us.
      </p>
      <p>
        The Content and the Marks are provided on the Application “AS IS” for your information and
        personal use only. Provided that you are eligible to use the Application, you are granted a limited
        license to access and use the Application and to download or print a copy of any portion of the
        Content to which you have properly gained access solely for your personal, non-commercial
        use. We reserve all rights not expressly granted to you in and to the Application, Content, and
        the Marks.
      </p>
    </f7-block>

    <f7-block-title>USER REPRESENTATIONS</f7-block-title>
    <f7-block>
      <p>By using the Application, you represent and warrant that: (1) all registration information you
        submit will be true, accurate, current, and complete; (2) you will maintain the accuracy of such
        information and promptly update such registration information as necessary; (3) you have the
        legal capacity and you agree to comply with these Terms of Use; (4) you are not under the age
        of 13; (5) not a minor in the jurisdiction in which you reside, or if a minor, you have received
        parental permission to use the Application; (6) you will not use the Application for any illegal or
        unauthorized purpose; and (7) your use of the Application will not violate any applicable law or
        regulation.
      </p>
      <p>
        If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the
        right to suspend or terminate your account and refuse any and all current or future use of the
        Application(or any portion thereof).
      </p>
    </f7-block>

    <f7-block-title>USER REGISTRATION</f7-block-title>
    <f7-block>
      <p>
        You may be required to register with the Application. You agree to keep your password
        confidential and will be responsible for all use of your account and password. We reserve the
        right to remove, reclaim, or change a username you select if we determine, in our sole
        discretion, that such username is inappropriate, obscene, or otherwise objectionable.
      </p>
    </f7-block>

    <f7-block-title>PROHIBITED ACTIVITIES</f7-block-title>
    <f7-block>
      <p>
        You may not access or use the Application for any purpose other than that for which we make
        the Application available. The Application may not be used in connection with any commercial
        endeavors except those that are specifically endorsed or approved by us.
      </p>

      <p>
        As a user of the Application, you agree not to:
      </p>

      <ul>
        <li>
          Systematically retrieve data or other content from the Application to create or compile,
          directly or indirectly, a collection, compilation, database, or directory without written
          permission from us.
        </li>
        <li>
          Make any unauthorized use of the Application, including collecting usernames and/or
          email addresses of users by electronic or other means for the purpose of sending
          unsolicited email, or creating user accounts by automated means or under false
          pretenses.
        </li>
        <li>
          Engage in unauthorized framing of or linking to the Application.
        </li>
        <li>
          Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive
          account information such as user passwords;
        </li>
        <li>
          Attempt to impersonate another user or person or use the username of another user.
        </li>
        <li>
          Use any information obtained from the Application in order to harass, abuse, or harm
          another person.
        </li>
        <li>
          Harass, annoy, intimidate, or threaten any of our employees or agents engaged in
          providing any portion of the Application to you.
        </li>
        <li>
          Delete the copyright or other proprietary rights notice from any Content.
        </li>
        <li>
          Copy or adapt the Application’s software, including but not limited to PHP, HTML,
          JavaScript, or other code.
        </li>
        <li>
          Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other
          material, including excessive use of capital letters and spamming (continuous posting of
          repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the
          Application or modifies, impairs, disrupts, alters, or interferes with the use, features,
          functions, operation, or maintenance of the Application.
        </li>
        <li>
          Upload or transmit (or attempt to upload or to transmit) any material that acts as a
          passive or active information collection or transmission mechanism, including without
          limitation, clear graphics interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or
          other similar devices (sometimes referred to as “spyware” or “passive collection
          mechanisms” or “pcms”).
        </li>
        <li>
          Except as may be the result of standard search engine or Internet browser usage, use,
          launch, develop, or distribute any automated system, including without limitation, any
          spider, robot, cheat utility, scraper, or offline reader that accesses the Application, or
          using or launching any unauthorized script or other software.
        </li>
        <li>
          Post inappropriate pictures that do not comply with the aim of the application.
        </li>
      </ul>
    </f7-block>

    <f7-block-title>USER GENERATED CONTRIBUTIONS</f7-block-title>
    <f7-block>
      <p>
        The Application may invite you to chat, contribute to, or participate in blogs, message boards,
        online forums, and other functionality, and may provide you with the opportunity to create,
        submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials

        to us or on the Application, including but not limited to text, writings, video, photographs,
        graphics, comments, suggestions, or personal information or other material (collectively,
        “Contributions”). Contributions may be viewable by other users of the Application and through
        third-party websites. As such, any Contributions you transmit may be treated as non-confidential
        and non-proprietary. When you create or make available any Contributions, you thereby
        represent and warrant that:
      </p>

      <ul>
        <li> The creation, distribution, transmission, public display, or performance, and the
          accessing, downloading, or copying of your Contributions do not and will not infringe the
          proprietary rights, including but not limited to the copyright, patent, trademark, trade
          secret, or moral rights of any third party.
        </li>
        <li>
          You are the creator and owner of or have the necessary licenses, rights, consents,
          releases, and permissions to use and to authorize us, the Application, and other users of
          the Application to use your Contributions in any manner contemplated by the Application
          and these Terms of Use.
        </li>
        <li>
          Your Contributions are not false, inaccurate, or misleading.
        </li>
        <li>
          Your Contributions are not unsolicited or unauthorized advertising, promotional
          materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of
          solicitation.
        </li>
        <li>
          Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous,
          slanderous, or otherwise objectionable (as determined by us).
        </li>
        <li>
          Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.
        </li>
        <li>
          Your Contributions do not advocate the violent overthrow of any government or incite,
          encourage, or threaten physical harm against another.
        </li>
        <li>
          Your Contributions do not violate any applicable law, regulation, or rule.
        </li>
        <li>
          Your Contributions do not violate the privacy or publicity rights of any third party.
        </li>
        <li>

          Your Contributions do not contain any material that solicits personal information from
          anyone under the age of 18 or exploits people under the age of 18 in a sexual or violent
          manner.
        </li>
      </ul>
      <p>
        Any use of the Application in violation of the foregoing violates these Terms of Use and may
        result in, among other things, termination or suspension of your rights to use the Application.
      </p>

    </f7-block>

    <f7-block-title>GUIDELINES FOR REVIEWS</f7-block-title>
    <f7-block>
      <p>
        We may provide you areas on the Application to leave reviews or ratings. When posting a review,
        you must comply with the following criteria: (1) your reviews should not contain offensive
        profanity, or abusive, racist, offensive, or hate language; (3) your reviews should not contain
        discriminatory references based on religion, race, gender, national origin, age, marital status,
        sexual orientation, or disability; (4) your reviews should not contain references to illegal activity;
        (5) you should not be affiliated with competitors if posting negative reviews; (6) you should not
        make any conclusions as to the legality of conduct; (7) you may not post any false or misleading
        statements; and (8) you may not organize a campaign encouraging others to post reviews,
        whether positive or negative.
      </p>

      <p>
        We may accept, reject, or remove reviews in our sole discretion. We have absolutely no
        obligation to screen reviews or to delete reviews, even if anyone considers reviews
        objectionable or inaccurate.
      </p>
    </f7-block>

    <f7-block-title>MOBILE APPLICATION LICENSE</f7-block-title>

    <f7-block-title>Use License</f7-block-title>
    <f7-block>
      <p>
        If you access the Application via a mobile application, then we grant you a revocable,
        non-exclusive, non-transferable, limited right to install and use the mobile application on
        wireless electronic devices owned or controlled by you, and to access and use the mobile
        application on such devices strictly in accordance with the terms and conditions of this mobile
        application license contained in these Terms of Use. You shall not: (1) decompile, reverse
        engineer, disassemble, attempt to derive the source code of, or decrypt the application; (2)
        make any modification, adaptation, improvement, enhancement, translation, or derivative work
        from the application; (3) violate any applicable laws, rules, or regulations in connection with your
        access or use of the application; (4) remove, alter, or obscure any proprietary notice (including
        any notice of copyright or trademark) posted by us or the licensors of the application; (5) use
        the application for any revenue generating endeavor, commercial enterprise, or other purpose
        for which it is not designed or intended; (6) make the application available over a network or
        other environment permitting access or use by multiple devices or users at the same time; (7)
        use the application for creating a product, service, or software that is, directly or indirectly,
        competitive with or in any way a substitute for the application; (8) use the application to send
        automated queries to any website or to send any unsolicited commercial e-mail; or (9) use any
        proprietary information or any of our interfaces or our other intellectual property in the design,
        development, manufacture, licensing, or distribution of any applications, accessories, or devices
        for use with the application.
      </p>
    </f7-block>


    <f7-block-title>Apple and Android Devices</f7-block-title>
    <f7-block>
      <p>
        The following terms apply when you use a mobile application obtained from either the Apple
        Store or Google Play (each an “App Distributor”) to access the Application: (1) the license
        granted to you for our mobile application is limited to a non-transferable license to use the
        application on a device that utilizes the Apple iOS or Android operating systems, as applicable,
        and in accordance with the usage rules set forth in the applicable App Distributor’s terms of
        service; (2) we are responsible for providing any maintenance and support services with respect
        to the mobile application as specified in the terms and conditions of this mobile application
        license contained in these Terms of Use or as otherwise required under applicable law, and you
        acknowledge that each App Distributor has no obligation whatsoever to furnish any
        maintenance and support services with respect to the mobile application; (3) in the event of any
        failure of the mobile application to conform to any applicable warranty, you may notify the
        applicable App Distributor, and the App Distributor, in accordance with its terms and policies,
        may refund the purchase price, if any, paid for the mobile application, and to the maximum
        extent permitted by applicable law, the App Distributor will have no other warranty obligation
        whatsoever with respect to the mobile application; (4) you represent and warrant that (i) you are
        not located in a country that is subject to a U.S. government embargo, or that has been
        designated by the U.S. government as a “terrorist supporting” country and (ii) you are not listed
        on any U.S. government list of prohibited or restricted parties; (5) you must comply with
        applicable third-party terms of agreement when using the mobile application, e.g., if you have a
        VoIP application, then you must not be in violation of their wireless data service agreement
        when using the mobile application; and (6) you acknowledge and agree that the App Distributors
        are third-party beneficiaries of the terms and conditions in this mobile application license
        contained in these Terms of Use, and that each App Distributor will have the right (and will be
        deemed to have accepted the right) to enforce the terms and conditions in this mobile
        application license contained in these Terms of Use against you as a third-party beneficiary
        thereof.
      </p>
    </f7-block>


    <f7-block-title>SOCIAL MEDIA</f7-block-title>

    <f7-block>
      <p>
        As part of the functionality of the Application, you may link your account with online accounts
        you have with third-party service providers (each such account, a “Third-Party Account”) by
        either: (1) providing your Third-Party Account login information through the Application; or (2)
        allowing us to access your Third-Party Account, as is permitted under the applicable terms and
        conditions that govern your use of each Third-Party Account. You represent and warrant that
        you are entitled to disclose your Third-Party Account login information to us and/or grant us
        access to your Third-Party Account, without breach by you of any of the terms and conditions
        that govern your use of the applicable Third-Party Account, and without obligating us to pay any
        fees or making us subject to any usage limitations imposed by the third-party service provider of
        the Third-Party Account. By granting us access to any Third-Party Accounts, you understand
        that (1) we may access, make available, and store (if applicable) any content that you have
        provided to and stored in your Third-Party Account (the “Social Network Content”) so that it is
        available on and through the Application via your account, including without limitation any friend
        lists and (2) we may submit to and receive from your Third-Party Account additional information
        to the extent you are notified when you link your account with the Third-Party Account.
        Depending on the Third-Party Accounts you choose and subject to the privacy settings that you
        have set in such Third-Party Accounts, personally identifiable information that you post to your
        Third-Party Accounts may be available on and through your account on the Application. Please
        note that if a Third-Party Account or associated service becomes unavailable or our access to
        such Third-Party Account is terminated by the third-party service provider, then Social Network
        Content may no longer be available on and through the Application. You will have the ability to
        disable the connection between your account on the Application and your Third-Party Accounts
        at any time. PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE
        PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY
        YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS. We make no effort to
        review any Social Network Content for any purpose, including but not limited to, for accuracy,
        legality, or non-infringement, and we are not responsible for any Social Network Content. You
        acknowledge and agree that we may access your email address book associated with a
        Third-Party Account and your contacts list stored on your mobile device or tablet computer
        solely for purposes of identifying and informing you of those contacts who have also registered
        to use the Application. You can deactivate the connection between the Application and your
        Third-Party Account by contacting us using the contact information below or through your
        account settings (if applicable). We will attempt to delete any information stored on our servers
        that was obtained through such Third-Party Account, except the username and profile picture
        that becomes associated with your account.
      </p>
    </f7-block>


    <f7-block-title>THIRD-PARTY WEBSITES AND CONTENT</f7-block-title>
    <f7-block>

      <p>
        The Application may contain (or you may be sent via the Application) links to other websites
        (“Third-Party Websites”) as well as articles, photographs, text, graphics, pictures, designs,
        music, sound, video, information, applications, software, and other content or items belonging to
        or originating from third parties (“Third-Party Content”). Such Third-Party Websites and
        Third-Party Content are not investigated, monitored, or checked for accuracy, appropriateness,
        or completeness by us, and we are not responsible for any Third-Party Websites accessed
        through the Application or any Third-Party Content posted on, available through, or installed
        from the Application, including the content, accuracy, offensiveness, opinions, reliability, privacy
        practices, or other policies of or contained in the Third-Party Websites or the Third-Party
        Content. Inclusion of, linking to, or permitting the use or installation of any Third-Party Websites
        or any Third-Party Content does not imply approval or endorsement thereof by us. If you decide
        to leave the Application and access the Third-Party Websites or to use or install any Third-Party
        Content, you do so at your own risk, and you should be aware these Terms of Use no longer
        govern. You should review the applicable terms and policies, including privacy and data
        gathering practices, of any website to which you navigate from the Application or relating to any
        applications you use or install from the Application. Any purchases you make through
        Third-Party Websites will be through other websites and from other companies, and we take no
        responsibility whatsoever in relation to such purchases which are exclusively between you and
        the applicable third party. You agree and acknowledge that we do not endorse the products or
        services offered on Third-Party Websites and you shall hold us harmless from any harm caused
        by your purchase of such products or services. Additionally, you shall hold us harmless from any
        losses sustained by you or harm caused to you relating to or resulting in any way from any
        Third-Party Content or any contact with Third-Party Websites.
      </p>
    </f7-block>


    <f7-block-title>APP MANAGEMENT</f7-block-title>
    <f7-block>
      <p>
        We reserve the right, but not the obligation, to: (1) monitor the Application for violations of these
        Terms of Use; (2) take appropriate legal action against anyone who, in our sole discretion,
        violates the law or these Terms of Use, including without limitation, reporting such user to law
        enforcement authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent
        technologically feasible) any of your Contributions or any portion thereof; (4) in our sole discretion and without limitation, notice, or liability, to
        remove from the Application or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems; and (5)
        otherwise manage the Application in a manner designed to protect our rights and property and to facilitate the proper functioning of the Application.
      </p>
    </f7-block>


    <f7-block-title>PRIVACY POLICY</f7-block-title>
    <f7-block>
      <p>
        We care about data privacy and security. Please review our Privacy Policy at https://urbigo.me/privacy-and-policy.​
        By using the Application, you agree to be bound by our Privacy Policy, which is incorporated into these Terms of Use.
      </p>
    </f7-block>


    <f7-block-title>CONTACT US</f7-block-title>
    <f7-block>
      <p>
        In order to resolve a complaint regarding the Application or to receive further information regarding use of the Application, please contact us at:<br/><br/>
        <span class="strong">UrbiGo DOO</span><br/>
        <span>Veljka Dugosevica no. 54, 11000 Belgrade, Serbia</span> <br/>
        <span>support@urbigo.me</span> <br/>
        <span>https://urbigo.me</span>
      </p>
    </f7-block>


    <f7-block>
      <span style="float:right;">Last Updated: 12 February, 2020</span>
    </f7-block>


  </f7-page>
</template>

<script>
  export default {}
</script>

<style scoped>
  p, ul {
    text-align: justify;
  }
</style>