<template>
  <f7-page>
    <!-- first steps -->
    <div class="swiper-container demo-swiper" v-if="!isConnected">

      <f7-swiper class="swiper-wrapper">
        <!-- First slide -->
        <f7-swiper-slide class="swiper-slide">
          <f7-block inset>
            <f7-block-title large>
              Hi, there!<br/>Enter your Device ID
            </f7-block-title>
            <p style="font-size: 18px;">
              <br/>
              You can find Device ID on the back of your UrbiGo product guide.
            </p>

            <div class="login-screen-content">
              <f7-list>
                <f7-list-input
                    id="deviceId"
                    label="Device ID"
                    type="text"
                    placeholder="Enter Device ID from package"
                    :value="deviceId"
                    @input="deviceId = $event.target.value"
                ></f7-list-input>

                <f7-list-input style="display: none;" type="hidden"></f7-list-input>
              </f7-list>
            </div>
          </f7-block>
        </f7-swiper-slide>

        <f7-swiper-slide class="swiper-slide">
          <f7-block inset>
            <f7-block-title large>
              Let's get started!
            </f7-block-title>
            <p style="font-size: 18px;">
              <br/>
              1. Check if your Urbi is plugged in.
              <br/><br/>
              2. Open your WiFi networks and click on your Urbi WiFi (UrbiGo-xxx).
              <br/><br/>
              3. Enter the password: <b>urbigo.me</b>
              <br/><br/><br/>
              Make sure to stay connected to Urbi WiFi for the next step.
            </p>
          </f7-block>
        </f7-swiper-slide>

        <f7-swiper-slide class="swiper-slide">
          <f7-block inset>
            <f7-block-title large>
              Connect Urbi to WiFi
            </f7-block-title>

            <p style="font-size: 18px;">
              1. Click on the link
              <f7-link href="http://192.168.4.1" target="_blank" class="external">http://192.168.4.1</f7-link>
              <br/><br/>
              2. Choose your WiFi, enter password, and press Connect.
            </p>
            <div style="margin:auto; text-align: center;">
              <p style="font-size: 14px; margin:0px;">This page will open.</p>
              <img src="/static/setup_screen1.png" style="width:70%; border: 1px solid #d7d7d8; border-radius: 5px;">
            </div>
          </f7-block>
        </f7-swiper-slide>

        <f7-swiper-slide class="swiper-slide">
          <f7-block inset>
            <f7-block-title large>
              Made it?
            </f7-block-title>

            <p style="font-size: 18px;">
              <br/>
              If you got the message confirming successful connection, congrats! 🎉
              <br/><br/>
              If you had any issues, don't worry. Please contact us and we'll help you right away!<br/>
              <a href="mailto:support@urbigo.me">support@urbigo.me</a>
            </p>
          </f7-block>
        </f7-swiper-slide>

        <f7-swiper-slide class="swiper-slide">
          <f7-block inset>
            <f7-block-title large>
              Reconnect to<br/> your home WiFi
            </f7-block-title>

            <p style="font-size: 18px;">
              <br/>
              To get back online, go to your WiFi settings, choose your network, and connect.<br/><br/>
              And now, let's jump to more fun stuff 🌱
              <br/><br/>
            </p>
          </f7-block>
        </f7-swiper-slide>

        <f7-swiper-slide class="swiper-slide">
          <f7-block inset>
            <f7-block-title large>
              Activate Urbi <br/>remote control
            </f7-block-title>

            <p style="font-size: 18px;">
              <br/>
              To control your mini garden with your phone, please insert the unique product ID number found in the
              Product Guide.
            </p>

            <div class="login-screen-content">
              <f7-list>
                <f7-list-input
                    id="deviceId"
                    label="Device ID"
                    type="text"
                    placeholder="Enter Device ID from package"
                    :value="deviceId"
                    @input="deviceId = $event.target.value"
                ></f7-list-input>

                <f7-list-input style="display: none;" type="hidden"></f7-list-input>
              </f7-list>

              <div class="login-screen-page">
                <f7-list class="no-margin">
                  <f7-list-button animate class="login-screen-content" @click="checkDevice">Check Connection
                  </f7-list-button>
                </f7-list>
              </div>
            </div>
          </f7-block>
        </f7-swiper-slide>

      </f7-swiper>
    </div>

    <f7-link v-if="!isConnected" class="prev-cont" @click="checkDeviceBackground"
             style="float:left; font-size: 25px; margin-left: 32px; font-weight: 300;">&#8592; Back
    </f7-link>
    <f7-link v-if="!isConnected" class="next-cont" @click="checkDeviceBackground"
             style="float:right; font-size: 25px; margin-right: 32px; font-weight: 300;">Next &#8594;
    </f7-link>

    <!-- second steps, when garden is connected -->
    <f7-block style="text-align: center; min-height:80px;" v-if="isConnected">
      <f7-block-title medium>Awesome!</f7-block-title>
      <p>
        You are now connected to your smart garden. Give your UrbiGo a name,
        tell it what you're growing and than click "Confirm" at the bottom of the page.
      </p>
    </f7-block>

    <f7-list no-hairlines-md v-if="isConnected">
      <f7-list-input
          clear-button
          type="text"
          placeholder="Enter UrbiGo name"
          :value="name"
          @input="name = $event.target.value"
      ></f7-list-input>
    </f7-list>

    <f7-block v-if="isConnected">
      <f7-row v-for="plants in this.$root.groupedPlants">
        <f7-col v-for="plant in plants">
          <p v-if="plant.title" class="ttl">{{ plant.title }}</p>
          <img v-if="plant.img" :src="plant.img" @click="choose(plant.id)" :class="classImg(plant.id)"/>
        </f7-col>
      </f7-row>
    </f7-block>

    <div v-if="isConnected" class="login-screen-page" style="margin-bottom: 3em;">
      <f7-list class="no-margin">
        <f7-list-button animate class="login-screen-content" style="font-size: 20px;" @click="saveDevice">Confirm
        </f7-list-button>
      </f7-list>
    </div>

  </f7-page>
</template>

<script>
export default {
  data() {
    return {
      chosen: [],
      deviceId: '',
      isConnected: false,
      name: '',
    };
  },
  methods: {
    choose(id) {
      if (this.chosen.includes(id)) {
        this.chosen = this.chosen.filter(item => item !== id)
      } else {
        this.chosen.push(id)
      }

    },
    classImg(id) {
      if (this.chosen.includes(id)) {
        return 'elevation-5'
      }
    },
    checkDevice() {
      const app = this.$f7;
      app.dialog.preloader('Connecting');

      this.$http.get('utilities/check-device', {params: {device_id: this.deviceId}}).then(
          response => {
            app.dialog.close();

            if (response.data.data.connected) {
              this.isConnected = true;
              //this.$root.showNotification('Awesome', 'Your garden is connected! Please provide a name of your garden to continue.');
            } else {
              this.$root.showNotification('Not connected', 'Seems like your UrbiGo garden is not connected to the WiFi. Please try again.');
            }
          },
          error => {
            app.dialog.close();
            this.$root.populateErrors('Ouch', error.data.message);
          }
      );
    },
    checkDeviceBackground() {
      this.$http.get('utilities/check-device', {params: {device_id: this.deviceId}}).then(
          response => {
            if (response.data.data.connected) {
              // maybe hit some notification, when this happen the screen will change up
              this.isConnected = true;
              //this.$root.showNotification('Awesome', 'Your garden is connected! Please provide a name of your garden to continue.');
            }
          },
          error => {
            // do nothing
          }
      );
    },
    saveDevice() {
      const app = this.$f7;
      app.dialog.preloader('Connecting');

      this.$http.post('green-cube', {device_id: this.deviceId, name: this.name, plants: this.chosen}).then(
          response => {
            app.dialog.close();
            this.$root.addDevice(response.data.data);
            location.href = "/"
            // maybe change in a future, f7 loading everything...
            // this.$root.showNotification('Awww', 'You have successfully completed the setup of your UrbiGo.')
          },
          error => {
            app.dialog.close();
            this.$root.populateErrors('Ouch', error.data.message);
          }
      )
    }
  },
  created() {
    const $$ = this.Dom7
    $$('.toolbar').hide()
  },
  mounted() {
    const app = this.$f7
    let swiper = app.swiper.create('.swiper-container', {
      navigation: {
        nextEl: '.next-cont',
        prevEl: '.prev-cont',
      },
    })

    // fix input padding
    this.Dom7('#deviceId .item-input').attr('style', 'padding-left:0px;')
  },
  beforeDestroy() {
    const $$ = this.Dom7
    $$('.toolbar').show()
  }
}
</script>

<style scoped>
.swiper-button-disabled {
  display: none;
}

.block {
  min-height: 350px;
}

.ttl {
  text-align: center;
  font-weight: bold;
  width: 100%;
  max-width: 100px;
}

.col img {
  width: 100%;
  max-width: 100px;
  padding: 3px;
  border-radius: 15px;
  background-color: #fff;
}

.pl0 {
  padding-left: 0px !important;
}


</style>