<template>
  <f7-page name="edit-garden">
    <f7-navbar title="Device Info" back-link="Learn"></f7-navbar>
    <f7-block-title large>Capsule Setup</f7-block-title>
    <f7-block class="mb">
      <p>UrbiGo capsules support the growth of your plants up to 3 months.</p>
      <p>During this time they will get optimal amount of:</p>
      <p>💧 &nbsp; Water, that is efficiently distributed to every plant you grow</p>
      <p>💚 &nbsp; Food, aka slow-releasing organic nutrients. No need for additional Fertilizers</p>
      <p>⭐ &nbsp; Air, needed for healthy root growth</p>
      <p>When your plants reach the top level of lights in UrbiGo, it is time to transplant or use them, and start
        growing a new set of UrbiGo capsules.</p>
      <p>Click below to choose your favorite among dozent spices, herbs, flowers and veggies!</p>
    </f7-block>
    <f7-list class="inset" style="border:1px solid #e3e3e3;">
      <f7-list-item title="Order new capsules" link="https://urbigo.me/plant-collections" external target="_blank"
                    class="external">
        <f7-icon slot="media" f7="arrow_clockwise_circle" style="color: #1caaf3;"></f7-icon>
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {},
  created() {
  },
  beforeDestroy() {
  }

}
</script>

<style scoped>
.mtb {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.mb {
  margin-bottom: 20px !important;
}
</style>