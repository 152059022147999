<template>
  <f7-page no-toolbar login-screen>
    <f7-navbar transparent title="" back-link="Sign In"></f7-navbar>


    <div v-if="!processing && !isCodeSent">
      <f7-login-screen-title>Join the green<br/> community</f7-login-screen-title>
      <f7-list form>
        <f7-list-input
          label="Full Name"
          type="text"
          placeholder="John Doe"
          :value="user.full_name"
          :info="user.error_full_name"
          @input="user.full_name = $event.target.value"
        ></f7-list-input>

        <f7-list-input
          label="Email"
          type="text"
          placeholder="john.doe@example.com"
          :value="user.email"
          :info="user.error_email"
          @input="user.email = $event.target.value"
        ></f7-list-input>

        <f7-list-input
          label="Super Secret Password"
          type="password"
          placeholder="********"
          :value="user.password"
          :info="user.error_password"
          @input="user.password = $event.target.value"
        ></f7-list-input>

        <!-- just to fix missing line on the last input -->
        <f7-list-input style="display: none;" type="hidden"></f7-list-input>
      </f7-list>

      <f7-list>
        <f7-toggle style="float:right; margin-right: 1em;" :checked="user.t_and_c" @toggle:change="user.t_and_c = !user.t_and_c"></f7-toggle>

        <f7-block-footer style="text-align: left;">
          I have read and agreed to the<br/>
          <f7-link href="/terms-and-conditions">Terms & Conditions</f7-link>
        </f7-block-footer>
      </f7-list>

      <f7-list>
        <f7-list-button @click="register">Create Account</f7-list-button>
      </f7-list>



      <f7-list style="margin-left: 1em; margin-right: 1em;">

        <f7-block-footer style="border-top:1px solid #e8e8ec; padding-top:1em;">
          Received account verification code?<br/>
          <f7-link @click="isCodeSent = true">Click here</f7-link>
        </f7-block-footer>
      </f7-list>


    </div>

    <f7-block v-else-if="processing" class="row demo-preloaders align-items-stretch text-align-center">
      <f7-col>
        <f7-preloader :size="42"></f7-preloader>
      </f7-col>
    </f7-block>

    <div v-else>

      <f7-block-footer>
        We sent you a code on email. <br/>Please enter that code.
      </f7-block-footer>

      <f7-list form>
        <f7-list-input
          label="Code from email"
          type="text"
          placeholder="Code"
          :value="code"
          @input="code = $event.target.value"
        ></f7-list-input>

        <!-- just to fix missing line on the last input -->
        <f7-list-input style="display: none;" type="hidden"></f7-list-input>

      </f7-list>

      <f7-list>
        <f7-list-button @click="registerConfirmation">Confirm Registration</f7-list-button>
      </f7-list>

    </div>

  </f7-page>
</template>

<script>
  export default {
    data() {
      return {
        processing: false,
        isCodeSent: false,
        code: null,
        user: {
          full_name: '',
          email: '',
          password: '',
          t_and_c: false,

          error_full_name: '',
          error_email: '',
          error_password: '',
        }
      };
    },
    mounted() {
      if (localStorage.getItem('info')) {
        let saved = JSON.parse(localStorage.getItem('info'));
        this.user.full_name = saved.full_name;
        this.user.email = saved.email;
      }
    },
    beforeDestroy: function () {
      localStorage.setItem('info', JSON.stringify(this.user))
    },
    methods: {
      register() {
        this.processing = true

        this.user.error_full_name = ''
        this.user.error_email = ''
        this.user.error_password = ''

        this.$http.post('register', this.user)
        .then(
          response => {
            this.isCodeSent = true
            this.processing = false
          },
          error => {
            this.processing = false
            const app = this.$f7

            if (typeof error.data.message === 'string') {
              app.dialog.alert(error.data.message);
            }
            else if(error.data.message) {
              for (const [key, value] of Object.entries(error.data.message)) {
                let msg = this.$root.getErrorMessage(value)
                switch (key) {
                  case 'full_name':
                    this.user.error_full_name = msg
                    break
                  case 'email':
                    this.user.error_email = msg
                    break
                  case 'password':
                    this.user.error_password = msg
                    break
                  default:
                    this.$root.populateErrors('Ouch', 'Error happened.')
                }
              }
            }
            else{
              this.$root.populateErrors('Ouch', 'Error happened, please try again.')
            }

          }
        )
      },
      registerConfirmation() {
        this.processing = true

        this.$http.post('register-confirmation', {'code': this.code})
        .then(
          response => {
            this.$root.initDevices(response.data.data.user.devices);
            this.$root.initUser(response.data.data.user);
            this.$root.setHeader(response.data.data.token);
            this.$root.initCountries();

            //this.$router.push('/no-device')
            //this.$root.showNotification('success', 'Welcome!', 'This is just great, you become a member of the GREEN community');

            location.href = "/";
          },
          error => {
            this.processing = false
            const app = this.$f7
            app.dialog.alert(error.data.message);
          }
        )

      }
    },
  }
</script>
<style scoped>
</style>
