<template>

  <f7-page>
    <f7-navbar title="Notifications" back-link="Back"></f7-navbar>


    <f7-block class="">
      <h2 medium class="text-align-center">Meet new UrbiGo feature 🚀</h2>

      <p>We've launched Notification center - a new feature to help you make the most out of your smart garden!</p>

      <p>It is in sync with your plants in UrbiGo and  includes:</p>

      <p>
        🌱<strong> Growing tips</strong> - Provides you with pro gardening tricks from A to Z. Learn how and when to prune your herbs, pollinate flowers, solve pest and mold bugs on soil, understand your plant's lifecycle, how to replant them etc.
      </p>
      <p>
        ℹ️ <strong>Device info</strong> - Get to know your UrbiGo. Know when to lift UrbiGo lights, remove transparent domes, how to setup UrbiGo capsules, clean water tank etc.
      </p>
      <p>🍵 <strong>Recipe hub</strong> - Bunch of cool recipes to use your edible plants, from cocktail to home made dishes.</p>

      <p>
        🙌 <strong>Your ideas are very welcome</strong> - feel free to share what you think people would find interesting and helpful.
      </p>


    </f7-block>

  </f7-page>
</template>

<script>
  export default {
    data() {
      return {}
    },
    methods: {},
    mounted() {
    }
  }
</script>

<style scoped>

</style>