<template>
  <f7-page>
    <f7-navbar title="Manual" back-link="Settings"></f7-navbar>

    <f7-block-title>1. Connect UrbiGo to your home WiFi</f7-block-title>
    <f7-block strong>
      <p>
        Connect the UrbiGo to a power outlet. On your mobile open WiFi networks and select smart garden hot spot "UrbiGo-XXXX"
        (XXXX wil be replaced with numbers). Click on it and insert the password "<span class="bold">urbigo.me</span>".
      </p>
    </f7-block>

    <f7-block-title>2. Go to http://192.168.4.1/ and find your WiFi network</f7-block-title>
    <f7-block strong>
      <p>
        Open your favourite browser and enter in the URL "http://192.168.4.1".
        On the page, select your home WiFi network from the list of nearby WiFi networks and insert the password.
        After this, you should see the screen that the garden is connected!
        <span>Open wifi networks again and switch back to your home wifi.</span>
      </p>
    </f7-block>

    <f7-block-title>3. Add UrbiGo unique ID</f7-block-title>
    <f7-block strong>
      <p>
        Find unique UrbiGo ID in the packaging and type it after registering in the app.
        You will then be transferred to the screen where you can monitor water, light and garden temperature.
      </p>
    </f7-block>

    <f7-block-title>4. Add water in the UrbiGo</f7-block-title>
    <f7-block strong>
      <p>
        Pour plain water in the black water container in your UrbiGo garden up to the white mark.
        Add plant capsules and seeds.
      </p>
    </f7-block>

    <f7-block-title>5. Personalize your settings</f7-block-title>
    <f7-block strong>
      <p>
        IN the upper right corner there is "config" option.
        Monitor water levels in real time and set lights to turn on and off automatically!
        Take your garden picture and track your plant's progress every day!
      </p>
    </f7-block>
  </f7-page>
</template>

<script>
  export default {
    name: "ReadManual",
  }
</script>

<style scoped>
</style>