<template>
  <f7-page>
    <f7-navbar title="About us" back-link="Settings"></f7-navbar>

    <f7-block>
      <p class="text-align-center">
        <img class="img-border-app-icon" src="/static/urbigo-logo.png">
      </p>
    </f7-block>

    <f7-block strong inset>
      <f7-block-title medium class="center-item">Who we Are</f7-block-title>
      <p>
        Together we are on a mission to bring nature closer to urban generation and make food gardening fun and
        simple for everybody!<br/><br/>
        We use technology not to destroy nature but to make it an integral part of our everyday lives.
        Thank you for joining - the journey has just started!
      </p>
    </f7-block>

    <f7-block class="text-align-center">
      <f7-link href="https://www.instagram.com/grow_with_urbigo/" icon-only external class="external">
        <f7-icon f7="logo_instagram" style="color: #e12f6c;"></f7-icon>
      </f7-link>
      <f7-link href="https://facebook.com/UrbiGo" icon-only external class="external">
        <f7-icon f7="logo_facebook" style="color: #3c5997;"></f7-icon>
      </f7-link>
      <f7-link href="https://www.linkedin.com/company/urbigo/" icon-only external class="external">
        <f7-icon f7="logo_linkedin" style="color: #0277b5;"></f7-icon>
      </f7-link>
      <f7-link href="https://twitter.com/urbigo" icon-only external class="external">
        <f7-icon class="fab fa-twitter-square" style="color: #4299ff;"></f7-icon>
      </f7-link>
    </f7-block>
  </f7-page>
</template>

<script>
  export default {
    name: "AboutUs",
    data() {
      return {}
    },
  }
</script>

<style scoped>
  .img-border-app-icon {
    border: solid 1px #cdcdcd;
    border-radius: 25px;
    width: 120px;
  }

  .icon {
    border: 0px solid #f05e2c;
    padding: 0px 15px;

    font-size: 36px;
    width: 36px;
    height: 36px;
  }


</style>
