<template>
  <f7-app :params="f7Params">

    <!-- main tabbed views -->
    <f7-views v-if="$root.loaded" tabs>
      <f7-toolbar tabbar labels bottom>
        <f7-link tab-link="#tab-1" text="Home" icon-material="home" tab-link-active></f7-link>
        <f7-link tab-link="#tab-2" text="Learn" icon-f7="book"></f7-link>
        <f7-link tab-link="#tab-3" text="Community" icon-f7="person_2"></f7-link>
        <f7-link tab-link="#tab-4" text="Settings" icon-ios="f7:gear" icon-f7="gear_alt"></f7-link>
      </f7-toolbar>

      <f7-view url="/tab1/" id="tab-1" tab @tab:show="log('home')" main tab-active/>
      <f7-view url="/tab2/" id="tab-2" tab @tab:show="log('learn')"/>
      <f7-view url="/tab3/" id="tab-3" tab @tab:show="log('community')"/>
      <f7-view url="/tab4/" id="tab-4" tab @tab:show="log('settings')"/>
    </f7-views>

    <!-- pre-loading page -->
    <f7-views v-else>
      <f7-page>
        <f7-block class="row text-align-center" style="margin-top:10em;">
          <f7-col>
            <f7-preloader :size="44"></f7-preloader>
          </f7-col>
        </f7-block>
      </f7-page>
    </f7-views>

  </f7-app>
</template>
<script>
import routes from '../js/routes.js';

export default {
  data() {
    return {
      f7Params: {
        id: 'urbigoApp',
        theme: 'auto', //ios, md
        routes,
        view: {},
        dialog: {
          // set default title for all dialog shortcuts
          title: ' ',
          // change default "OK" button text
          //buttonOk: 'Done',
        },
        popup: {
          closeOnEscape: true,
        },
        sheet: {
          closeOnEscape: true,
        },
        popover: {
          closeOnEscape: true,
        },
        actions: {
          closeOnEscape: true,
        }
      },
    };
  },
  methods: {
    log(tabName) {
      this.$root.collect('LOAD_SCREEN', null, {'screen': tabName})
    }
  }
};
</script>