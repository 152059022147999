<template>
  <f7-page>
    <f7-navbar title="Transplanting guide" back-link="Back"></f7-navbar>
    <f7-block>
      <h2 medium class="text-align-center">Learn how to transplant your plants</h2>
      <p class="text-align-center" style="font-size: 26px; margin-bottom:0px;">🌱🌱🌱</p>
      <p>
        Have your plants reached the highest light bar level? If that is the case,
        and you want to keep nurturing your greens, the best thing to do is to transplant them to a bigger pot.
      </p>
      <p>
        We made a complete transplanting video guide, with few hacks that will make the process as easy as possible!
      </p>
    </f7-block>
    <f7-list class="inset" style="border:1px solid #e3e3e3;">
      <f7-list-item title="Go to YouTube video" link="https://www.youtube.com/watch?v=Z-G8V_mOcIw" external="true"
                    target="_blank" class="external">
        <f7-icon slot="media" f7="play_rectangle_fill" style="color: #FF0000;"></f7-icon>
      </f7-list-item>
    </f7-list>
    <f7-block>
      <p><span style="font-size: 20px;">🥳</span> Ready to grow new batch? Order new set of UrbiGo capsules now!
        <span style="font-size: 20px;">🙌🏻</span></p>
    </f7-block>
    <f7-list class="inset" style="border:1px solid #e3e3e3;">
      <f7-list-item title="Check New Capsules" link="https://urbigo.me/plant-collections" external="true"
                    target="_blank" class="external">
        <f7-icon slot="media" f7="leaf_arrow_circlepath" style="color: #45A15B;"></f7-icon>
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>
<script>
export default {
  data() {
    return {}
  }
}
</script>
<style scoped>
</style>