<template>
  <f7-page>
    <f7-navbar title="Error" back-link="Back"></f7-navbar>
    <f7-block strong inset>
      <h3>Uuuups!</h3>
      <p>Something went wrong, please restart the app.</p>
    </f7-block>
  </f7-page>
</template>
<script>
  export default {};
</script>