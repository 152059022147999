<template>
  <f7-page>
    <f7-navbar title="Change Password" back-link="Settings"></f7-navbar>

    <f7-list no-hairlines-md>
      <f7-list-input
        type="password"
        placeholder="Old password"
        :value="passwordData.password"
        @input="passwordData.password = $event.target.value" clear-button>
        <f7-icon f7="lock_circle" slot="media" style="color: #5e9680;"></f7-icon>
      </f7-list-input>
    </f7-list>

    <f7-list no-hairlines-md>
      <f7-list-input
        type="password"
        placeholder="New Password"
        :value="passwordData.new_password"
        @input="passwordData.new_password = $event.target.value" clear-button>
        <f7-icon f7="lock_circle" slot="media" style="color: #5e9680;"></f7-icon>
      </f7-list-input>

      <f7-list-input
        type="password"
        placeholder="Repeat Password"
        :value="passwordData.confirm_password"
        @input="passwordData.confirm_password = $event.target.value" clear-button>
        <f7-icon f7="lock_rotation" slot="media" style="color: #5e9680;"></f7-icon>
      </f7-list-input>
    </f7-list>

    <div class="login-screen-page">
      <f7-list class="no-margin">
        <f7-list-button animate class="login-screen-content" @click="savePassword">Confirm Changes</f7-list-button>
      </f7-list>
    </div>

  </f7-page>
</template>

<script>
  export default {
    data() {
      return {
        passwordData: {
          password: '',
          new_password: '',
          confirm_password: ''
        }
      }
    },
    mounted() {
    },
    created() {
    },
    methods: {
      savePassword() {
        this.$root.$http.post('profile/password', this.passwordData).then(
          response => {
            this.passwordData.password = ''
            this.passwordData.new_password = ''
            this.passwordData.confirm_password = ''
            this.$root.showNotification('Password changed', 'Your password has been changed successfully.');
          },
          error => {
            // this.$f7 == app
            this.$f7.dialog.alert(error.data.message);
          }
        )
      }
    }
  }
</script>

<style scoped>
  .no-margin {
    margin: 0px;
  }
</style>