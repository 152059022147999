<template>
  <f7-page>
    <f7-navbar title="Water warning" back-link="Back"></f7-navbar>
    <f7-block class="">
      <h2 medium class="text-align-center">Your UrbiGo needs <br/>more water</h2>
      <p>Seems like your smart nano garden will soon run out of water. 🌱</p>
      <p>
        Please take one UrbiGo capsule out and check if there is little or no water at all,
        and add 600ml (two cups) of plain, tap water to the water tank. 💧</p>

    </f7-block>
    <f7-block>
      <p>
        If UrbiGo water tank is full, but you're still getting water level warning,
        please click here to solve problem quickly.
      </p>
    </f7-block>
    <f7-list style="">
      <f7-list-item title="Water level debug" link="/water-level-debug/">
        <f7-icon slot="media" f7="drop" style="color:rgb(31 123 243);"></f7-icon>
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {},
  mounted() {
  }
}
</script>