export default {
  data() {
    return {
      logs: {}
    }
  },
  created(){
    this.logs = []
  },
  methods: {
    addLog(name) {
      // console.log(name)
      // this.logs.unshift({time: Date.now(), name: name})
    },

    sendLog() {
    },
    clearLog() {

    }
  }
};