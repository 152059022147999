<template>
  <f7-page name="edit-garden">
    <f7-navbar :title="device.name" back-link="Back"></f7-navbar>

    <f7-block-title>Garden name</f7-block-title>
    <f7-list no-hairlines-md>
      <f7-list-input
        clear-button
        type="text"
        placeholder="Enter your garden name"
        :value="device.name"
        :info="name_error"
        @input="device.name = $event.target.value; checkName()">
      </f7-list-input>
    </f7-list>

    <f7-list class="mb5">
      <f7-list-item title="Device ID" :after="device.device_id">
        <f7-icon slot="media" f7="asterisk_circle" style="background-color:#8e8e93;"></f7-icon>
      </f7-list-item>

      <f7-list-item title="Setup date" :after="device.created_at_dm">
        <f7-icon slot="media" f7="calendar_today" style="background-color:#1f7bf3;"></f7-icon>
      </f7-list-item>

      <f7-list-item title="Automation">
        <f7-icon slot="media" style="background-color:#62bb48;" f7="stopwatch"></f7-icon>
        <f7-toggle slot="after" :checked="device.is_automatic" @toggle:change="device.is_automatic = !device.is_automatic"></f7-toggle>
      </f7-list-item>

      <f7-list-item v-if="device.is_automatic" title="Set start time">
        <f7-list-input
          id="time"
          label=""
          type="time"
          placeholder="Choose time to start lights"
          :value="device.start_time"
          @input="device.start_time = $event.target.value; device.start_time = device.start_time ? device.start_time : '08:00';"
        >
        </f7-list-input>

        <f7-icon slot="media" f7="play_circle" style="background-color:#62bb48;"></f7-icon>
      </f7-list-item>

    </f7-list>
    <f7-block class="b-desc">
      Automation is on: Lights in your device will turn on at the same preset time every day,
      and turn off after they finish the cycle for that day.
    </f7-block>
  </f7-page>

</template>

<script>
  export default {
    data() {
      return {
        device: null,
        name_error: '',
        old_name: ''
      }
    },
    methods: {
      checkName() {
        if (this.device.name.length > 15) {
          this.name_error = 'Please keep it short. You enter ' + this.device.name.length + ' letters out of 15'
        } else {
          this.name_error = ''
        }
      },
      save() {
        // don't know if this ever happen
        if (!this.device.start_time) {
          this.device.start_time = '08:00'
        }

        let data = {
          name: this.device.name,
          start_time: this.device.start_time,
          is_automatic: this.device.is_automatic
        }

        this.$http.put('green-cube/' + this.device.id, data).then(
          response => {
            // all good
          },
          error => {
            this.device.name = this.old_name
            this.$root.populateErrors("Error saving data", error.data.message)
          }
        )
      }
    },
    created() {
      this.device = this.$root.getCurrent()

      this.old_name = this.device.name
    },
    mounted() {
      if (this.$f7route.query.time_trigger) {
        let input = document.querySelectorAll('input[type=time]')[0]
        input.focus()
      }
    },
    beforeDestroy() {
      this.save()
    }

  }
</script>

<style scoped>
  .b-desc {
    margin-top: 0px;
    color: #747474;
  }

  .mb5 {
    margin-bottom: 5px;
  }

  .icon {
    border: 0px solid #f05e2c;
    border-radius: 5px;
    padding: 6px;

    color: white;
    font-size: 18px;
    width: 18px;
    height: 18px;
  }

</style>