export default {
  data() {
    return {
      user: null,
      countries: {},
      unread_notifications: null,
      notifications: null,
      is_dev_user: false
    }
  },
  methods: {
    initUser(user) {
      let meta = document.getElementById("user-id");
      if (meta) {
        meta.setAttribute("content", user.id);
      }

      if (user.id == 42 || user.id == 23 || user.id == 18 || user.id == 19 || user.id == 846) {
        this.is_dev_user = true
      }

      this.user = user;
    },
    getUser() {
      return this.user;
    },
    initCountries() {
      this.$http.get('countries-list').then(
        response => {
          this.countries = response.data.data;
        },
        error => {
          this.$root.populateErrors("Error", error.data.message);
        }
      )
    },
    setUnreadNotifications(unread) {
      if (unread > 99) {
        unread = 99
      }

      if (this.unread_notifications === null) {
        this.unread_notifications = unread
      }

      if (unread != this.unread_notifications || this.notifications === null) {
        // fetch new notifications
        this.$http.get('notifications').then(
          response => {
            this.notifications = response.data.data
          },
          error => {
            this.$root.populateErrors('Error', error.data.message)
          }
        )
      }

      this.unread_notifications = unread
    },
    collect(eventString, deviceId = null, additional = null){
      this.$root.$http.get('collect', {params: {'event': eventString, 'device_id': deviceId, 'params': additional}});
    }

  }
};