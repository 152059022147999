<template>
  <div class="page-content page-content-full">

    <div class="coverpage coverpage-full walkthrough-buttons walkthrough-has-button">
      <router-link to="/login"
                   class="get-started-custom  button button-round button-full bg-highlight uppercase ultrabold">
        Get Started
      </router-link>

      <div class="walkthrough-slider owl-carousel">
        <div class="cover-item">
          <div class="cover-content cover-content-center">
            <!--<i class="center-text color-blue-dark bottom-30 fa-7x fa fa-power-off"></i>-->
            <img src="/theme/myimages/splash-screen1.png" class="splash-screen-img"/>
            <br>
            <h2 class="regular center-text bottom-20 bolder font-28">
              Control & Grow
            </h2>
            <p class="center-text bottom-50 font-13">
              Control UrbiGo lights with one click or set them on "automatic" mode. Monitor plant's water level & get notified when to add more.
            </p>
          </div>
          <div class="cover-overlay overlay"></div>
        </div>

        <div class="cover-item">
          <div class="cover-content cover-content-center">
            <!--<i class="center-text color-yellow-dark bottom-30 fa-7x fa fa-star"></i>-->
            <img src="/theme/myimages/splash-screen2.png" class="splash-screen-img"/>

            <br>
            <h2 class="regular center-text bottom-20 bolder font-28">
              Take a green quest
            </h2>
            <p class="center-text bottom-50 font-13">
              Learn fun stuff about your plants as they flourish, discover what others are growing and show off your smart gardening skills!
            </p>
          </div>
          <div class="cover-overlay overlay"></div>
        </div>

        <div class="cover-item">
          <div class="cover-content cover-content-center">
            <!--<i class="center-text color-green-dark bottom-30 fa-7x fa fa-gift"></i>-->
            <img src="/theme/myimages/splash-screen3.png" class="splash-screen-img"/>

            <br>
            <h2 class="regular center-text bottom-20 bolder font-28">
              Collect rewards
            </h2>
            <p class="center-text bottom-50 font-13">
              Collect fresh & delicious recipes to consume your greens when they come of age and enjoy the taste of your own mini triumph!
            </p>
          </div>
          <div class="cover-overlay overlay"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    mounted() {
      init_template();
    }
  }
</script>

<style scoped>
  .splash-screen-img {
    width: 80% !important;
    margin: auto;
  }
</style>